export const ENABLE_CACHE = 'ENABLE_CACHE'
export const DISABLE_CACHE = 'DISABLE_CACHE'

export const FILTER_BY_COUNTY = 'FILTER_BY_COUNTY'
export const FILTER_BY_SUB_COUNTY = 'FILTER_BY_SUB_COUNTY'
export const FILTER_BY_FACILITY = 'FILTER_BY_FACILITY'
export const FILTER_BY_PARTNER = 'FILTER_BY_PARTNER'
export const FILTER_BY_AGENCY = 'FILTER_BY_AGENCY'
export const FILTER_BY_PBFW = 'FILTER_BY_PBFW';
export const FILTER_BY_PROJECT = 'FILTER_BY_PROJECT'
export const FILTER_BY_FROM_DATE = 'FILTER_BY_FROM_DATE'
export const FILTER_BY_TO_DATE = 'FILTER_BY_TO_DATE'
export const FILTER_BY_GENDER = 'FILTER_BY_GENDER'
export const FILTER_BY_DATIM_AGE_GROUP = 'FILTER_BY_DATIM_AGE_GROUP'
export const FILTER_BY_DATIM_AGE_POPULATION = 'FILTER_BY_DATIM_AGE_POPULATION';
export const FILTER_BY_POPULATION_TYPE = 'FILTER_BY_POPULATION_TYPE'
export const FILTER_BY_LATEST_PREGNANCY = 'FILTER_BY_LATEST_PREGNANCY'
export const FILTER_BY_INDICATOR = 'FILTER_BY_INDICATOR'
export const FILTER_BY_EMR_TYPE = 'FILTER_BY_EMR_TYPE';

export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'

export const CHANGE_HOME_TAB = 'CHANGE_HOME_TAB'
export const CHANGE_RR_TAB = 'CHANGE_RR_TAB'
export const CHANGE_HTS_TAB = 'CHANGE_HTS_TAB'
export const CHANGE_CT_TAB = 'CHANGE_CT_TAB'
export const CHANGE_SD_TAB = 'CHANGE_SD_TAB'
export const CHANGE_OPERATIONAL_AND_HIS_TAB = 'CHANGE_OPERATIONAL_AND_HIS_TAB'
export const CHANGE_PMTCT_RRI_TAB = 'CHANGE_PMTCT_RRI_TAB';

export const ENABLE_STICKY_FILTER = 'ENABLE_STICKY_FILTER'
export const ENABLE_COUNTY_FILTER = 'ENABLE_COUNTY_FILTER'
export const ENABLE_SUB_COUNTY_FILTER = 'ENABLE_SUB_COUNTY_FILTER'
export const ENABLE_FACILITY_FILTER = 'ENABLE_FACILITY_FILTER'
export const ENABLE_PARTNER_FILTER = 'ENABLE_PARTNER_FILTER'
export const ENABLE_AGENCY_FILTER = 'ENABLE_AGENCY_FILTER'
export const ENABLE_PBFW_FILTER = 'ENABLE_PBFW_FILTER';
export const ENABLE_PROJECT_FILTER = 'ENABLE_PROJECT_FILTER'
export const ENABLE_FROM_DATE_FILTER = 'ENABLE_FROM_DATE_FILTER'
export const ENABLE_TO_DATE_FILTER = 'ENABLE_TO_DATE_FILTER'
export const ENABLE_GENDER_FILTER = 'ENABLE_GENDER_FILTER'
export const ENABLE_DATIM_AGE_GROUP_FILTER = 'ENABLE_DATIM_AGE_GROUP_FILTER'
export const ENABLE_DATIM_AGE_POPULATION_FILTER = 'ENABLE_DATIM_AGE_POPULATION_FILTER'
export const ENABLE_POPULATION_TYPE_FILTER = 'ENABLE_POPULATION_TYPE_FILTER'
export const ENABLE_LATEST_PREGNANCY_FILTER = 'ENABLE_LATEST_PREGNANCY_FILTER'
export const ENABLE_INDICATOR_FILTER = 'ENABLE_INDICATOR_FILTER'
export const ENABLE_EMR_TYPE_FILTER = 'ENABLE_EMR_TYPE_FILTER';
export const DISABLE_STICKY_FILTER = 'DISABLE_STICKY_FILTER'
export const DISABLE_COUNTY_FILTER = 'DISABLE_COUNTY_FILTER'
export const DISABLE_SUB_COUNTY_FILTER = 'DISABLE_SUB_COUNTY_FILTER'
export const DISABLE_FACILITY_FILTER = 'DISABLE_FACILITY_FILTER'
export const DISABLE_PARTNER_FILTER = 'DISABLE_PARTNER_FILTER'
export const DISABLE_AGENCY_FILTER = 'DISABLE_AGENCY_FILTER'
export const DISABLE_PBFW_FILTER = 'DISABLE_PBFW_FILTER';
export const DISABLE_PROJECT_FILTER = 'DISABLE_PROJECT_FILTER'
export const DISABLE_FROM_DATE_FILTER = 'DISABLE_FROM_DATE_FILTER'
export const DISABLE_TO_DATE_FILTER = 'DISABLE_TO_DATE_FILTER'
export const DISABLE_GENDER_FILTER = 'DISABLE_GENDER_FILTER'
export const DISABLE_DATIM_AGE_GROUP_FILTER = 'DISABLE_DATIM_AGE_GROUP_FILTER'
export const DISABLE_DATIM_AGE_POPULATION_FILTER = 'DISABLE_DATIM_AGE_POPULATION_FILTER'
export const DISABLE_POPULATION_TYPE_FILTER = 'DISABLE_POPULATION_TYPE_FILTER'
export const DISABLE_LATEST_PREGNANCY_FILTER = 'DISABLE_LATEST_PREGNANCY_FILTER'
export const DISABLE_INDICATOR_FILTER = 'DISABLE_INDICATOR_FILTER'
export const DISABLE_EMR_TYPE_FILTER = 'DISABLE_EMR_TYPE_FILTER';

export const STORE_USER = 'STORE_USER'
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT'
export const USER_EXPIRED = 'USER_EXPIRED'
export const STORE_USER_ERROR = 'STORE_USER_ERROR'
export const LOADING_USER = 'LOADING_USER'

export const API_START = 'API_START'
export const API_SUCCESS = 'API_SUCCESS'
export const API_ERROR = 'API_ERROR'

export const GPS_SITES_REQUEST = 'GPS_SITES_REQUEST'
export const GPS_SITES_FETCH = 'GPS_SITES_FETCH'
export const GPS_SITES_REQUEST_FAILED = 'GPS_SITES_REQUEST_FAILED'

export const RR_SITES_REQUEST = 'RR_SITES_REQUEST'
export const RR_SITES_FETCH = 'RR_SITES_FETCH'
export const RR_SITES_REQUEST_FAILED = 'RR_SITES_REQUEST_FAILED'

export const HTS_SITES_REQUEST = 'HTS_SITES_REQUEST'
export const HTS_SITES_FETCH = 'HTS_SITES_FETCH'
export const HTS_SITES_REQUEST_FAILED = 'HTS_SITES_REQUEST_FAILED'

export const HTS_LINKAGE_POSITIVE_TRENDS_REQUEST = 'HTS_LINKAGE_POSITIVE_TRENDS_REQUEST'
export const HTS_LINKAGE_POSITIVE_TRENDS_FETCH = 'HTS_LINKAGE_POSITIVE_TRENDS_FETCH'
export const HTS_LINKAGE_POSITIVE_TRENDS_REQUEST_FAILED = 'HTS_LINKAGE_POSITIVE_TRENDS_REQUEST_FAILED'
export const HTS_LINKAGE_NUMBER_NOT_LINKED_BY_FACILITY_REQUEST = 'HTS_LINKAGE_NUMBER_NOT_LINKED_BY_FACILITY_REQUEST'
export const HTS_LINKAGE_NUMBER_NOT_LINKED_BY_FACILITY_FETCH = 'HTS_LINKAGE_NUMBER_NOT_LINKED_BY_FACILITY_FETCH'
export const HTS_LINKAGE_NUMBER_NOT_LINKED_BY_FACILITY_REQUEST_FAILED = 'HTS_LINKAGE_NUMBER_NOT_LINKED_BY_FACILITY_REQUEST_FAILED'

export const HTS_NEW_ON_PREP_REQUEST = 'HTS_NEW_ON_PREP_REQUEST';
export const HTS_NEW_ON_PREP_FETCH = 'HTS_NEW_ON_PREP_FETCH';
export const HTS_NEW_ON_PREP_FAILED = 'HTS_NEW_ON_PREP_FAILED';

export const CT_PREP_REQUEST = 'CT_PREP_REQUEST';
export const CT_PREP_FETCH = 'CT_PREP_FETCH';
export const CT_PREP_FAILED = 'CT_PREP_FAILED';

export const CT_PREP_TRENDS_REQUEST = 'CT_PREP_TRENDS_REQUEST';
export const CT_PREP_TRENDS_FETCH = 'CT_PREP_TRENDS_FETCH';
export const CT_PREP_TRENDS_FAILED = 'CT_PREP_TRENDS_FAILED';

export const CT_PREP_AGE_SEX_TRENDS_REQUEST = 'CT_PREP_AGE_SEX_TRENDS_REQUEST';
export const CT_PREP_AGE_SEX_TRENDS_FETCH = 'CT_PREP_AGE_SEX_TRENDS_FETCH';
export const CT_PREP_AGE_SEX_TRENDS_FAILED = 'CT_PREP_AGE_SEX_TRENDS_FAILED';

export const CT_PREP_MONTH3_REFILL_AGE_SEX_REQUEST = 'CT_PREP_MONTH3_REFILL_AGE_SEX_REQUEST';
export const CT_PREP_MONTH3_REFILL_AGE_SEX_FETCH = 'CT_PREP_MONTH3_REFILL_AGE_SEX_FETCH';
export const CT_PREP_MONTH3_REFILL_AGE_SEX_FAILED = 'CT_PREP_MONTH3_REFILL_AGE_SEX_FAILED';

export const CT_PREP_MONTH1_REFILL_AGE_SEX_REQUEST = 'CT_PREP_MONTH1_REFILL_AGE_SEX_REQUEST';
export const CT_PREP_MONTH1_REFILL_AGE_SEX_FETCH = 'CT_PREP_MONTH1_REFILL_AGE_SEX_FETCH';
export const CT_PREP_MONTH1_REFILL_AGE_SEX_FAILED = 'CT_PREP_MONTH1_REFILL_AGE_SEX_FAILED';

export const CT_PREP_MONTH1_REFILL_REQUEST = 'CT_PREP_MONTH1_REFILL_REQUEST';
export const CT_PREP_MONTH1_REFILL_FETCH = 'CT_PREP_MONTH1_REFILL_FETCH';
export const CT_PREP_MONTH1_REFILL_FAILED = 'CT_PREP_MONTH1_REFILL_FAILED';

export const CT_PREP_MONTH3_REFILL_REQUEST = 'CT_PREP_MONTH3_REFILL_REQUEST';
export const CT_PREP_MONTH3_REFILL_FETCH = 'CT_PREP_MONTH3_REFILL_FETCH';
export const CT_PREP_MONTH3_REFILL_FAILED = 'CT_PREP_MONTH3_REFILL_FAILED';

export const CT_PREP_TESTED_MONTH1_REFILL_REQUEST = 'CT_PREP_TESTED_MONTH1_REFILL_REQUEST';
export const CT_PREP_TESTED_MONTH1_REFILL_FETCH = 'CT_PREP_TESTED_MONTH1_REFILL_FETCH';
export const CT_PREP_TESTED_MONTH1_REFILL_FAILED = 'CT_PREP_TESTED_MONTH1_REFILL_FAILED';

export const CT_PREP_TESTED_MONTH3_REFILL_REQUEST = 'CT_PREP_TESTED_MONTH3_REFILL_REQUEST';
export const CT_PREP_TESTED_MONTH3_REFILL_FETCH = 'CT_PREP_TESTED_MONTH3_REFILL_FETCH';
export const CT_PREP_TESTED_MONTH3_REFILL_FAILED = 'CT_PREP_TESTED_MONTH3_REFILL_FAILED';

export const PREP_SCREENED_REQUEST = 'PREP_SCREENED_REQUEST';
export const PREP_SCREENED_FETCH = 'PREP_SCREENED_FETCH';
export const PREP_SCREENED_FAILED = 'PREP_SCREENED_FAILED';

export const PREP_DIAGNOSED_STI_TRENDS_REQUEST = 'PREP_DIAGNOSED_STI_TRENDS_REQUEST';
export const PREP_DIAGNOSED_STI_TRENDS_FETCH = 'PREP_DIAGNOSED_STI_TRENDS_FETCH';
export const PREP_DIAGNOSED_STI_TRENDS_FAILED = 'PREP_DIAGNOSED_STI_TRENDS_FAILED';

export const PREP_SCREENING_OUTCOME_REQUEST = 'PREP_SCREENING_OUTCOME_REQUEST';
export const PREP_SCREENING_OUTCOME_FETCH = 'PREP_SCREENING_OUTCOME_FETCH';
export const PREP_SCREENING_OUTCOME_FAILED = 'PREP_SCREENING_OUTCOME_FAILED';

export const PREP_TESTED_TOTAL_REQUEST = 'PREP_TESTED_TOTAL_REQUEST';
export const PREP_TESTED_TOTAL_FETCH = 'PREP_TESTED_TOTAL_FETCH';
export const PREP_TESTED_TOTAL_FAILED = 'PREP_TESTED_TOTAL_FAILED';

export const PREP_ELIGIBLE_REQUEST = 'PREP_ELIGIBLE_REQUEST';
export const PREP_ELIGIBLE_FETCH = 'PREP_ELIGIBLE_FETCH';
export const PREP_ELIGIBLE_FAILED = 'PREP_ELIGIBLE_FAILED';

export const PREP_ELIGIBLE_AGEGROUP_REQUEST = 'PREP_ELIGIBLE_AGEGROUP_REQUEST';
export const PREP_ELIGIBLE_AGEGROUP_FETCH = 'PREP_ELIGIBLE_AGEGROUP_FETCH';
export const PREP_ELIGIBLE_AGEGROUP_FAILED = 'PREP_ELIGIBLE_AGEGROUP_FAILED';

export const PREP_NEW_TRENDS_REQUEST = 'PREP_NEW_TRENDS_REQUEST';
export const PREP_NEW_TRENDS_FETCH = 'PREP_NEW_TRENDS_FETCH';
export const PREP_NEW_TRENDS_FAILED = 'PREP_NEW_TRENDS_FAILED';

export const PREP_STI_TREATMENT_OUTCOMES_REQUEST = 'PREP_STI_TREATMENT_OUTCOMES_REQUEST';
export const PREP_STI_TREATMENT_OUTCOMES_FETCH = 'PREP_STI_TREATMENT_OUTCOMES_FETCH';
export const PREP_STI_TREATMENT_OUTCOMES_FAILED = 'PREP_STI_TREATMENT_OUTCOMES_FAILED';

export const PREP_NEW_AGESEX_REQUEST = 'PREP_NEW_AGESEX_REQUEST';
export const PREP_NEW_AGESEX_FETCH = 'PREP_NEW_AGESEX_FETCH';
export const PREP_NEW_AGESEX_FAILED = 'PREP_NEW_AGESEX_FAILED';

export const HTS_PREP_DISCONTINUATION_REQUEST = 'HTS_PREP_DISCONTINUATION_REQUEST';
export const HTS_PREP_DISCONTINUATION_FETCH = 'HTS_PREP_DISCONTINUATION_FETCH';
export const HTS_PREP_DISCONTINUATION_FAILED = 'HTS_PREP_DISCONTINUATION_FAILED';

export const HTS_PREP_DISCONTINUATION_TRENDS_REQUEST = 'HTS_PREP_DISCONTINUATION_TRENDS_REQUEST';
export const HTS_PREP_DISCONTINUATION_TRENDS_FETCH = 'HTS_PREP_DISCONTINUATION_TRENDS_FETCH';
export const HTS_PREP_DISCONTINUATION_TRENDS_FAILED = 'HTS_PREP_DISCONTINUATION_TRENDS_FAILED';

export const HTS_PREP_DISCONTINUATION_REASON_REQUEST = 'HTS_PREP_DISCONTINUATION_REASON_REQUEST';
export const HTS_PREP_DISCONTINUATION_REASON_FETCH = 'HTS_PREP_DISCONTINUATION_REASON_FETCH';
export const HTS_PREP_DISCONTINUATION_REASON_FAILED = 'HTS_PREP_DISCONTINUATION_REASON_FAILED';

export const CT_SITES_REQUEST = 'CT_SITES_REQUEST'
export const CT_SITES_FETCH = 'CT_SITES_FETCH'
export const CT_SITES_REQUEST_FAILED = 'CT_SITES_REQUEST_FAILED'

export const CT_CURRENT_NEW_ON_ART_OVERVIEW_REQUEST = 'CT_CURRENT_NEW_ON_ART_OVERVIEW_REQUEST'
export const CT_CURRENT_NEW_ON_ART_OVERVIEW_FETCH = 'CT_CURRENT_NEW_ON_ART_OVERVIEW_FETCH'
export const CT_CURRENT_NEW_ON_ART_OVERVIEW_REQUEST_FAILED = 'CT_CURRENT_NEW_ON_ART_OVERVIEW_REQUEST_FAILED'

export const CT_NEW_ON_ART_OVERVIEW_REQUEST = 'CT_NEW_ON_ART_OVERVIEW_REQUEST'
export const CT_NEW_ON_ART_OVERVIEW_FETCH = 'CT_NEW_ON_ART_OVERVIEW_FETCH'
export const CT_NEW_ON_ART_OVERVIEW_REQUEST_FAILED = 'CT_NEW_ON_ART_OVERVIEW_REQUEST_FAILED'
export const CT_NEW_ON_ART_TRENDS_REQUEST = 'CT_NEW_ON_ART_TRENDS_REQUEST'
export const CT_NEW_ON_ART_TRENDS_FETCH = 'CT_NEW_ON_ART_TRENDS_FETCH'
export const CT_NEW_ON_ART_TRENDS_REQUEST_FAILED = 'CT_NEW_ON_ART_TRENDS_REQUEST_FAILED'
export const CT_NEW_ON_ART_BY_AGE_SEX_REQUEST = 'CT_NEW_ON_ART_BY_AGE_SEX_REQUEST'
export const CT_NEW_ON_ART_BY_AGE_SEX_FETCH = 'CT_NEW_ON_ART_BY_AGE_SEX_FETCH'
export const CT_NEW_ON_ART_BY_AGE_SEX_REQUEST_FAILED = 'CT_NEW_ON_ART_BY_AGE_SEX_REQUEST_FAILED'
export const CT_MEDIAN_TIME_TO_ART_START_BY_YEAR_REQUEST = 'CT_MEDIAN_TIME_TO_ART_START_BY_YEAR_REQUEST'
export const CT_MEDIAN_TIME_TO_ART_START_BY_YEAR_FETCH = 'CT_MEDIAN_TIME_TO_ART_START_BY_YEAR_FETCH'
export const CT_MEDIAN_TIME_TO_ART_START_BY_YEAR_REQUEST_FAILED = 'CT_MEDIAN_TIME_TO_ART_START_BY_YEAR_REQUEST_FAILED'
export const CT_MEDIAN_TIME_TO_ART_START_BY_COUNTY_REQUEST = 'CT_MEDIAN_TIME_TO_ART_START_BY_COUNTY_REQUEST'
export const CT_MEDIAN_TIME_TO_ART_START_BY_COUNTY_FETCH = 'CT_MEDIAN_TIME_TO_ART_START_BY_COUNTY_FETCH'
export const CT_MEDIAN_TIME_TO_ART_START_BY_COUNTY_REQUEST_FAILED = 'CT_MEDIAN_TIME_TO_ART_START_BY_COUNTY_REQUEST_FAILED'
export const CT_MEDIAN_TIME_TO_ART_START_BY_PARTNER_REQUEST = 'CT_MEDIAN_TIME_TO_ART_START_BY_PARTNER_REQUEST'
export const CT_MEDIAN_TIME_TO_ART_START_BY_PARTNER_FETCH = 'CT_MEDIAN_TIME_TO_ART_START_BY_PARTNER_FETCH'
export const CT_MEDIAN_TIME_TO_ART_START_BY_PARTNER_REQUEST_FAILED = 'CT_MEDIAN_TIME_TO_ART_START_BY_PARTNER_REQUEST_FAILED'
export const CT_TIME_FROM_DIAGNOSIS_TO_ART_START_REQUEST = 'CT_TIME_FROM_DIAGNOSIS_TO_ART_START_REQUEST'
export const CT_TIME_FROM_DIAGNOSIS_TO_ART_START_FETCH = 'CT_TIME_FROM_DIAGNOSIS_TO_ART_START_FETCH'
export const CT_TIME_FROM_DIAGNOSIS_TO_ART_START_REQUEST_FAILED = 'CT_TIME_FROM_DIAGNOSIS_TO_ART_START_REQUEST_FAILED'

export const CT_CURRENT_ON_ART_OVERVIEW_REQUEST = 'CT_CURRENT_ON_ART_OVERVIEW_REQUEST'
export const CT_CURRENT_ON_ART_OVERVIEW_FETCH = 'CT_CURRENT_ON_ART_OVERVIEW_FETCH'
export const CT_CURRENT_ON_ART_OVERVIEW_REQUEST_FAILED = 'CT_CURRENT_ON_ART_OVERVIEW_REQUEST_FAILED'
export const CT_CURRENT_ON_ART_BY_AGE_SEX_REQUEST = 'CT_CURRENT_ON_ART_BY_AGE_SEX_REQUEST'
export const CT_CURRENT_ON_ART_BY_AGE_SEX_FETCH = 'CT_CURRENT_ON_ART_BY_AGE_SEX_FETCH'
export const CT_CURRENT_ON_ART_BY_AGE_SEX_REQUEST_FAILED = 'CT_CURRENT_ON_ART_BY_AGE_SEX_REQUEST_FAILED'
export const CT_CURRENT_ON_ART_BY_COUNTY_REQUEST = 'CT_CURRENT_ON_ART_BY_COUNTY_REQUEST'
export const CT_CURRENT_ON_ART_BY_COUNTY_FETCH = 'CT_CURRENT_ON_ART_BY_COUNTY_FETCH'
export const CT_CURRENT_ON_ART_BY_COUNTY_REQUEST_FAILED = 'CT_CURRENT_ON_ART_BY_COUNTY_REQUEST_FAILED'
export const CT_CURRENT_ON_ART_BY_PARTNER_REQUEST = 'CT_CURRENT_ON_ART_BY_PARTNER_REQUEST'
export const CT_CURRENT_ON_ART_BY_PARTNER_FETCH = 'CT_CURRENT_ON_ART_BY_PARTNER_FETCH'
export const CT_CURRENT_ON_ART_BY_PARTNER_REQUEST_FAILED = 'CT_CURRENT_ON_ART_BY_PARTNER_REQUEST_FAILED'
export const CT_CURRENT_ON_ART_DISTRIBUTION_BY_COUNTY_REQUEST = 'CT_CURRENT_ON_ART_DISTRIBUTION_BY_COUNTY_REQUEST'
export const CT_CURRENT_ON_ART_DISTRIBUTION_BY_COUNTY_FETCH = 'CT_CURRENT_ON_ART_DISTRIBUTION_BY_COUNTY_FETCH'
export const CT_CURRENT_ON_ART_DISTRIBUTION_BY_COUNTY_REQUEST_FAILED = 'CT_CURRENT_ON_ART_DISTRIBUTION_BY_COUNTY_REQUEST_FAILED'
export const CT_CURRENT_ON_ART_DISTRIBUTION_BY_PARTNER_REQUEST = 'CT_CURRENT_ON_ART_DISTRIBUTION_BY_PARTNER_REQUEST'
export const CT_CURRENT_ON_ART_DISTRIBUTION_BY_PARTNER_FETCH = 'CT_CURRENT_ON_ART_DISTRIBUTION_BY_PARTNER_FETCH'
export const CT_CURRENT_ON_ART_DISTRIBUTION_BY_PARTNER_REQUEST_FAILED = 'CT_CURRENT_ON_ART_DISTRIBUTION_BY_PARTNER_REQUEST_FAILED'

export const CT_CURRENT_ON_ART_REQUEST = 'CT_CURRENT_ON_ART_REQUEST'
export const CT_CURRENT_ON_ART_FETCH = 'CT_CURRENT_ON_ART_PARTNER_FETCH'
export const CT_CURRENT_ON_ART_FAILED = 'CT_CURRENT_ON_ART_FAILED'

export const CT_ART_OPTIMIZATION_OVERVIEW_REQUEST = 'CT_ART_OPTIMIZATION_OVERVIEW_REQUEST'
export const CT_ART_OPTIMIZATION_OVERVIEW_FETCH = 'CT_ART_OPTIMIZATION_OVERVIEW_FETCH'
export const CT_ART_OPTIMIZATION_OVERVIEW_REQUEST_FAILED = 'CT_ART_OPTIMIZATION_OVERVIEW_REQUEST_FAILED'
export const CT_ART_OPTIMIZATION_CURRENT_BY_REGIMEN_REQUEST = 'CT_ART_OPTIMIZATION_CURRENT_BY_REGIMEN_REQUEST'
export const CT_ART_OPTIMIZATION_CURRENT_BY_REGIMEN_FETCH = 'CT_ART_OPTIMIZATION_CURRENT_BY_REGIMEN_FETCH'
export const CT_ART_OPTIMIZATION_CURRENT_BY_REGIMEN_REQUEST_FAILED = 'CT_ART_OPTIMIZATION_CURRENT_BY_REGIMEN_REQUEST_FAILED'
export const CT_ART_OPTIMIZATION_CURRENT_BY_AGE_SEX_REQUEST = 'CT_ART_OPTIMIZATION_CURRENT_BY_AGE_SEX_REQUEST'
export const CT_ART_OPTIMIZATION_CURRENT_BY_AGE_SEX_FETCH = 'CT_ART_OPTIMIZATION_CURRENT_BY_AGE_SEX_FETCH'
export const CT_ART_OPTIMIZATION_CURRENT_BY_AGE_SEX_REQUEST_FAILED = 'CT_ART_OPTIMIZATION_CURRENT_BY_AGE_SEX_REQUEST_FAILED'
export const CT_ART_OPTIMIZATION_CURRENT_BY_COUNTY_REQUEST = 'CT_ART_OPTIMIZATION_CURRENT_BY_COUNTY_REQUEST'
export const CT_ART_OPTIMIZATION_CURRENT_BY_COUNTY_FETCH = 'CT_ART_OPTIMIZATION_CURRENT_BY_COUNTY_FETCH'
export const CT_ART_OPTIMIZATION_CURRENT_BY_COUNTY_REQUEST_FAILED = 'CT_ART_OPTIMIZATION_CURRENT_BY_COUNTY_REQUEST_FAILED'
export const CT_ART_OPTIMIZATION_CURRENT_BY_PARTNER_REQUEST = 'CT_ART_OPTIMIZATION_CURRENT_BY_PARTNER_REQUEST'
export const CT_ART_OPTIMIZATION_CURRENT_BY_PARTNER_FETCH = 'CT_ART_OPTIMIZATION_CURRENT_BY_PARTNER_FETCH'
export const CT_ART_OPTIMIZATION_CURRENT_BY_PARTNER_REQUEST_FAILED = 'CT_ART_OPTIMIZATION_CURRENT_BY_PARTNER_REQUEST_FAILED'
export const CT_ART_OPTIMIZATION_NEW_BY_YEAR_REQUEST = 'CT_ART_OPTIMIZATION_NEW_BY_YEAR_REQUEST'
export const CT_ART_OPTIMIZATION_NEW_BY_YEAR_FETCH = 'CT_ART_OPTIMIZATION_NEW_BY_YEAR_FETCH'
export const CT_ART_OPTIMIZATION_NEW_BY_YEAR_REQUEST_FAILED = 'CT_ART_OPTIMIZATION_NEW_BY_YEAR_REQUEST_FAILED'
export const CT_ART_OPTIMIZATION_NEW_BY_COUNTY_REQUEST = 'CT_ART_OPTIMIZATION_NEW_BY_COUNTY_REQUEST'
export const CT_ART_OPTIMIZATION_NEW_BY_COUNTY_FETCH = 'CT_ART_OPTIMIZATION_NEW_BY_COUNTY_FETCH'
export const CT_ART_OPTIMIZATION_NEW_BY_COUNTY_REQUEST_FAILED = 'CT_ART_OPTIMIZATION_NEW_BY_COUNTY_REQUEST_FAILED'
export const CT_ART_OPTIMIZATION_NEW_BY_PARTNER_REQUEST = 'CT_ART_OPTIMIZATION_NEW_BY_PARTNER_REQUEST'
export const CT_ART_OPTIMIZATION_NEW_BY_PARTNER_FETCH = 'CT_ART_OPTIMIZATION_NEW_BY_PARTNER_FETCH'
export const CT_ART_OPTIMIZATION_NEW_BY_PARTNER_REQUEST_FAILED = 'CT_ART_OPTIMIZATION_NEW_BY_PARTNER_REQUEST_FAILED'
export const CT_ART_OPTIMIZATION_REGIMEN_DISTRIBUTION_BY_WEIGHT_BANDS_REQUEST = 'CT_ART_OPTIMIZATION_REGIMEN_DISTRIBUTION_BY_WEIGHT_BANDS_REQUEST'
export const CT_ART_OPTIMIZATION_REGIMEN_DISTRIBUTION_BY_WEIGHT_BANDS_FETCH = 'CT_ART_OPTIMIZATION_REGIMEN_DISTRIBUTION_BY_WEIGHT_BANDS_FETCH'
export const CT_ART_OPTIMIZATION_REGIMEN_DISTRIBUTION_BY_WEIGHT_BANDS_FAILED = 'CT_ART_OPTIMIZATION_REGIMEN_DISTRIBUTION_BY_WEIGHT_BANDS_FAILED'
export const CT_ART_OPTIMIZATION_REGIMEN_DISTRIBUTION_BY_AGE_BANDS_REQUEST = 'CT_ART_OPTIMIZATION_REGIMEN_DISTRIBUTION_BY_AGE_BANDS_REQUEST'
export const CT_ART_OPTIMIZATION_REGIMEN_DISTRIBUTION_BY_AGE_BANDS_FETCH = 'CT_ART_OPTIMIZATION_REGIMEN_DISTRIBUTION_BY_AGE_BANDS_FETCH'
export const CT_ART_OPTIMIZATION_REGIMEN_DISTRIBUTION_BY_AGE_BANDS_FAILED = 'CT_ART_OPTIMIZATION_REGIMEN_DISTRIBUTION_BY_AGE_BANDS_FAILED'

export const CT_ADVERSE_EVENTS_BY_AGE_SEX_REQUEST = 'CT_ADVERSE_EVENTS_BY_AGE_SEX_REQUEST'
export const CT_ADVERSE_EVENTS_BY_AGE_SEX_FETCH = 'CT_ADVERSE_EVENTS_BY_AGE_SEX_FETCH'
export const CT_ADVERSE_EVENTS_BY_AGE_SEX_REQUEST_FAILED = 'CT_ADVERSE_EVENTS_BY_AGE_SEX_REQUEST_FAILED'
export const CT_ADVERSE_EVENTS_CLIENTS_BY_AGE_SEX_REQUEST = 'CT_ADVERSE_EVENTS_CLIENTS_BY_AGE_SEX_REQUEST'
export const CT_ADVERSE_EVENTS_CLIENTS_BY_AGE_SEX_FETCH = 'CT_ADVERSE_EVENTS_CLIENTS_BY_AGE_SEX_FETCH'
export const CT_ADVERSE_EVENTS_CLIENTS_BY_AGE_SEX_REQUEST_FAILED = 'CT_ADVERSE_EVENTS_CLIENTS_BY_AGE_SEX_REQUEST_FAILED'
export const CT_ADVERSE_EVENTS_SEVERITY_GRADING_REQUEST = 'CT_ADVERSE_EVENTS_SEVERITY_GRADING_REQUEST'
export const CT_ADVERSE_EVENTS_SEVERITY_GRADING_FETCH = 'CT_ADVERSE_EVENTS_SEVERITY_GRADING_FETCH'
export const CT_ADVERSE_EVENTS_SEVERITY_GRADING_REQUEST_FAILED = 'CT_ADVERSE_EVENTS_SEVERITY_GRADING_REQUEST_FAILED'
export const CT_ADVERSE_EVENTS_SEVERITY_ACTIONS_REQUEST = 'CT_ADVERSE_EVENTS_SEVERITY_ACTIONS_REQUEST'
export const CT_ADVERSE_EVENTS_SEVERITY_ACTIONS_FETCH = 'CT_ADVERSE_EVENTS_SEVERITY_ACTIONS_FETCH'
export const CT_ADVERSE_EVENTS_SEVERITY_ACTIONS_REQUEST_FAILED = 'CT_ADVERSE_EVENTS_SEVERITY_ACTIONS_REQUEST_FAILED'
export const CT_ADVERSE_EVENTS_REPORTED_WITH_SEVERITY_LEVELS_REQUEST = 'CT_ADVERSE_EVENTS_REPORTED_WITH_SEVERITY_LEVELS_REQUEST'
export const CT_ADVERSE_EVENTS_REPORTED_WITH_SEVERITY_LEVELS_FETCH = 'CT_ADVERSE_EVENTS_REPORTED_WITH_SEVERITY_LEVELS_FETCH'
export const CT_ADVERSE_EVENTS_REPORTED_WITH_SEVERITY_LEVELS_REQUEST_FAILED = 'CT_ADVERSE_EVENTS_REPORTED_WITH_SEVERITY_LEVELS_REQUEST_FAILED'
export const CT_ADVERSE_EVENTS_ACTIONS_BY_DRUGS_REQUEST = 'CT_ADVERSE_EVENTS_ACTIONS_BY_DRUGS_REQUEST'
export const CT_ADVERSE_EVENTS_ACTIONS_BY_DRUGS_FETCH = 'CT_ADVERSE_EVENTS_ACTIONS_BY_DRUGS_FETCH'
export const CT_ADVERSE_EVENTS_ACTIONS_BY_DRUGS_REQUEST_FAILED = 'CT_ADVERSE_EVENTS_ACTIONS_BY_DRUGS_REQUEST_FAILED'
export const CT_ADVERSE_EVENTS_ACTIONS_BY_DRUGS_NEW_REQUEST = 'CT_ADVERSE_EVENTS_ACTIONS_BY_DRUGS_NEW_REQUEST'
export const CT_ADVERSE_EVENTS_ACTIONS_BY_DRUGS_NEW_FETCH = 'CT_ADVERSE_EVENTS_ACTIONS_BY_DRUGS_NEW_FETCH'
export const CT_ADVERSE_EVENTS_ACTIONS_BY_DRUGS_NEW_REQUEST_FAILED = 'CT_ADVERSE_EVENTS_ACTIONS_BY_DRUGS_NEW_REQUEST_FAILED'
export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_BY_CAUSATIVE_DRUGS_REQUEST = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_BY_CAUSATIVE_DRUGS_REQUEST'
export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_BY_CAUSATIVE_DRUGS_FETCH = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_BY_CAUSATIVE_DRUGS_FETCH'
export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_BY_CAUSATIVE_DRUGS_FAILED = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_BY_CAUSATIVE_DRUGS_FAILED'
export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_AE_RELATED_TO_ART_REQUEST = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_AE_RELATED_TO_ART_REQUEST'
export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_AE_RELATED_TO_ART_FETCH = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_AE_RELATED_TO_ART_FETCH'
export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_AE_RELATED_TO_ART_FAILED = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_AE_RELATED_TO_ART_FAILED'
export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_CHANGED_REQUEST = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_CHANGED_REQUEST'
export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_CHANGED_FETCH = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_CHANGED_FETCH'
export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_CHANGED_FAILED = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_CHANGED_FAILED'

export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_WAS_STOPPED_REQUEST = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_WAS_STOPPED_REQUEST'
export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_WAS_STOPPED_FETCH = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_WAS_STOPPED_FETCH'
export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_WAS_STOPPED_FAILED = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_WAS_STOPPED_FAILED'

export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_WAS_NOT_ALTERED_REQUEST = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_WAS_NOT_ALTERED_REQUEST'
export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_WAS_NOT_ALTERED_FETCH = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_WAS_NOT_ALTERED_FETCH'
export const CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_WAS_NOT_ALTERED_FAILED = 'CT_ADVERSE_EVENTS_PROPORTION_OF_PLHIV_WITH_AE_REGIMEN_WAS_NOT_ALTERED_FAILED'

export const CT_DSD_STABILITY_STATUS_BY_AGE_SEX_REQUEST = 'CT_DSD_STABILITY_STATUS_BY_AGE_SEX_REQUEST'
export const CT_DSD_STABILITY_STATUS_BY_AGE_SEX_FETCH = 'CT_DSD_STABILITY_STATUS_BY_AGE_SEX_FETCH'
export const CT_DSD_STABILITY_STATUS_BY_AGE_SEX_REQUEST_FAILED = 'CT_DSD_STABILITY_STATUS_BY_AGE_SEX_REQUEST_FAILED'
export const CT_DSD_STABILITY_STATUS_BY_COUNTY_REQUEST = 'CT_DSD_STABILITY_STATUS_BY_COUNTY_REQUEST'
export const CT_DSD_STABILITY_STATUS_BY_COUNTY_FETCH = 'CT_DSD_STABILITY_STATUS_BY_COUNTY_FETCH'
export const CT_DSD_STABILITY_STATUS_BY_COUNTY_REQUEST_FAILED = 'CT_DSD_STABILITY_STATUS_BY_COUNTY_REQUEST_FAILED'
export const CT_DSD_STABILITY_STATUS_BY_PARTNER_REQUEST = 'CT_DSD_STABILITY_STATUS_BY_PARTNER_REQUEST'
export const CT_DSD_STABILITY_STATUS_BY_PARTNER_FETCH = 'CT_DSD_STABILITY_STATUS_BY_PARTNER_FETCH'
export const CT_DSD_STABILITY_STATUS_BY_PARTNER_REQUEST_FAILED = 'CT_DSD_STABILITY_STATUS_BY_PARTNER_REQUEST_FAILED'
export const CT_DSD_UNSTABLE_REQUEST = 'CT_DSD_UNSTABLE_REQUEST'
export const CT_DSD_UNSTABLE_FETCH = 'CT_DSD_UNSTABLE_FETCH'
export const CT_DSD_UNSTABLE_REQUEST_FAILED = 'CT_DSD_UNSTABLE_REQUEST_FAILED'
export const CT_DSD_UPTAKE_OVERALL_REQUEST = 'CT_DSD_UPTAKE_OVERALL_REQUEST'
export const CT_DSD_UPTAKE_OVERALL_FETCH = 'CT_DSD_UPTAKE_OVERALL_FETCH'
export const CT_DSD_UPTAKE_OVERALL_FAILED = 'CT_DSD_UPTAKE_OVERALL_FAILED'
export const CT_DSD_STABLE_OVERALL_REQUEST = 'CT_DSD_STABLE_OVERALL_REQUEST'
export const CT_DSD_STABLE_OVERALL_FETCH = 'CT_DSD_STABLE_OVERALL_FETCH'
export const CT_DSD_STABLE_OVERALL_FAILED = 'CT_DSD_STABLE_OVERALL_FAILED'
export const CT_DSD_STABLE_MMD_MODELS_REQUEST = 'CT_DSD_STABLE_MMD_MODELS_REQUEST'
export const CT_DSD_STABLE_MMD_MODELS_FETCH = 'CT_DSD_STABLE_MMD_MODELS_FETCH'
export const CT_DSD_STABLE_MMD_MODELS_REQUEST_FAILED = 'CT_DSD_STABLE_MMD_MODELS_REQUEST_FAILED'
export const CT_DSD_APPOINTMENT_DURATION_BY_STABILITY_STATUS_REQUEST = 'CT_DSD_APPOINTMENT_DURATION_BY_STABILITY_STATUS_REQUEST'
export const CT_DSD_APPOINTMENT_DURATION_BY_STABILITY_STATUS_FETCH = 'CT_DSD_APPOINTMENT_DURATION_BY_STABILITY_STATUS_FETCH'
export const CT_DSD_APPOINTMENT_DURATION_BY_STABILITY_STATUS_REQUEST_FAILED = 'CT_DSD_APPOINTMENT_DURATION_BY_STABILITY_STATUS_REQUEST_FAILED'
export const CT_DSD_APPOINTMENT_DURATION_BY_SEX_REQUEST = 'CT_DSD_APPOINTMENT_DURATION_BY_SEX_REQUEST'
export const CT_DSD_APPOINTMENT_DURATION_BY_SEX_FETCH = 'CT_DSD_APPOINTMENT_DURATION_BY_SEX_FETCH'
export const CT_DSD_APPOINTMENT_DURATION_BY_SEX_REQUEST_FAILED = 'CT_DSD_APPOINTMENT_DURATION_BY_SEX_REQUEST_FAILED'
export const CT_DSD_APPOINTMENT_DURATION_BY_AGE_REQUEST = 'CT_DSD_APPOINTMENT_DURATION_BY_AGE_REQUEST'
export const CT_DSD_APPOINTMENT_DURATION_BY_AGE_FETCH = 'CT_DSD_APPOINTMENT_DURATION_BY_AGE_FETCH'
export const CT_DSD_APPOINTMENT_DURATION_BY_AGE_REQUEST_FAILED = 'CT_DSD_APPOINTMENT_DURATION_BY_AGE_REQUEST_FAILED'
export const CT_DSD_APPOINTMENT_DURATION_BY_COUNTY_REQUEST = 'CT_DSD_APPOINTMENT_DURATION_BY_COUNTY_REQUEST'
export const CT_DSD_APPOINTMENT_DURATION_BY_COUNTY_FETCH = 'CT_DSD_APPOINTMENT_DURATION_BY_COUNTY_FETCH'
export const CT_DSD_APPOINTMENT_DURATION_BY_COUNTY_REQUEST_FAILED = 'CT_DSD_APPOINTMENT_DURATION_BY_COUNTY_REQUEST_FAILED'
export const CT_DSD_APPOINTMENT_DURATION_BY_PARTNER_REQUEST = 'CT_DSD_APPOINTMENT_DURATION_BY_PARTNER_REQUEST'
export const CT_DSD_APPOINTMENT_DURATION_BY_PARTNER_FETCH = 'CT_DSD_APPOINTMENT_DURATION_BY_PARTNER_FETCH'
export const CT_DSD_APPOINTMENT_DURATION_BY_PARTNER_REQUEST_FAILED = 'CT_DSD_APPOINTMENT_DURATION_BY_PARTNER_REQUEST_FAILED'
export const CT_DSD_UPTAKE_OVERALL_BY_SEX_REQUEST = 'CT_DSD_UPTAKE_OVERALL_BY_SEX_REQUEST'
export const CT_DSD_UPTAKE_OVERALL_BY_SEX_FETCH = 'CT_DSD_UPTAKE_OVERALL_BY_SEX_FETCH'
export const CT_DSD_UPTAKE_OVERALL_BY_SEX_FAILED = 'CT_DSD_UPTAKE_OVERALL_BY_SEX_FAILED'

export const CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_SEX_REQUEST = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_SEX_REQUEST'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_SEX_FETCH = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_SEX_FETCH'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_SEX_REQUEST_FAILED = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_SEX_REQUEST_FAILED'

export const CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_SEX_VLDONE_REQUEST = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_SEX_VLDONE_REQUEST'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_SEX_VLDONE_FETCH = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_SEX_VLDONE_FETCH'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_SEX_VLDONE_FAILED = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_SEX_VLDONE_FAILED'

export const CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_LESS_INTENSE_REQUEST = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_LESS_INTENSE_REQUEST'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_LESS_INTENSE_FETCH = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_LESS_INTENSE_FETCH'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_LESS_INTENSE_FAILED = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_LESS_INTENSE_FAILED'

export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_REQUEST = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_REQUEST'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_FETCH = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_FETCH'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_FAILED = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_FAILED'

export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_EAC_REQUEST = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_EAC_REQUEST'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_EAC_FETCH = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_EAC_FETCH'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_EAC_FAILED = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_EAC_FAILED'

export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_RECEIVED_FOLLOW_TESTS_REQUEST = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_RECEIVED_FOLLOW_TESTS_REQUEST'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_RECEIVED_FOLLOW_TESTS_FETCH = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_RECEIVED_FOLLOW_TESTS_FETCH'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_RECEIVED_FOLLOW_TESTS_FAILED = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_RECEIVED_FOLLOW_TESTS_FAILED'

export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_RECEIVED_FOLLOW_TESTS_ALL_REQUEST = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_RECEIVED_FOLLOW_TESTS_ALL_REQUEST'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_RECEIVED_FOLLOW_TESTS_ALL_FETCH = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_RECEIVED_FOLLOW_TESTS_ALL_FETCH'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_RECEIVED_FOLLOW_TESTS_ALL_FAILED = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_RECEIVED_FOLLOW_TESTS_ALL_FAILED'

export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_SECOND_LINE_REGIMENT_REQUEST = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_SECOND_LINE_REGIMENT_REQUEST'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_SECOND_LINE_REGIMENT_FETCH = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_SECOND_LINE_REGIMENT_FETCH'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_SECOND_LINE_REGIMENT_FAILED = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_GT_1000_COPIES_SECOND_LINE_REGIMENT_FAILED'

export const CT_MEDIAN_TIME_TO_1ST_VL_BY_YEAR_REQUEST = 'CT_MEDIAN_TIME_TO_1ST_VL_BY_YEAR_REQUEST'
export const CT_MEDIAN_TIME_TO_1ST_VL_BY_YEAR_FETCH = 'CT_MEDIAN_TIME_TO_1ST_VL_BY_YEAR_FETCH'
export const CT_MEDIAN_TIME_TO_1ST_VL_BY_YEAR_REQUEST_FAILED = 'CT_MEDIAN_TIME_TO_1ST_VL_BY_YEAR_REQUEST_FAILED'
export const CT_MEDIAN_TIME_TO_1ST_VL_BY_COUNTY_REQUEST = 'CT_MEDIAN_TIME_TO_1ST_VL_BY_COUNTY_REQUEST'
export const CT_MEDIAN_TIME_TO_1ST_VL_BY_COUNTY_FETCH = 'CT_MEDIAN_TIME_TO_1ST_VL_BY_COUNTY_FETCH'
export const CT_MEDIAN_TIME_TO_1ST_VL_BY_COUNTY_REQUEST_FAILED = 'CT_MEDIAN_TIME_TO_1ST_VL_BY_COUNTY_REQUEST_FAILED'
export const CT_MEDIAN_TIME_TO_1ST_VL_BY_PARTNER_REQUEST = 'CT_MEDIAN_TIME_TO_1ST_VL_BY_PARTNER_REQUEST'
export const CT_MEDIAN_TIME_TO_1ST_VL_BY_PARTNER_FETCH = 'CT_MEDIAN_TIME_TO_1ST_VL_BY_PARTNER_FETCH'
export const CT_MEDIAN_TIME_TO_1ST_VL_BY_PARTNER_REQUEST_FAILED = 'CT_MEDIAN_TIME_TO_1ST_VL_BY_PARTNER_REQUEST_FAILED'
export const CT_VIRAL_LOAD_UPTAKE_BY_SEX_REQUEST = 'CT_VIRAL_LOAD_UPTAKE_BY_SEX_REQUEST'
export const CT_VIRAL_LOAD_UPTAKE_BY_SEX_FETCH = 'CT_VIRAL_LOAD_UPTAKE_BY_SEX_FETCH'
export const CT_VIRAL_LOAD_UPTAKE_BY_SEX_REQUEST_FAILED = 'CT_VIRAL_LOAD_UPTAKE_BY_SEX_REQUEST_FAILED'
export const CT_VIRAL_LOAD_UPTAKE_BY_AGE_REQUEST = 'CT_VIRAL_LOAD_UPTAKE_BY_AGE_REQUEST'
export const CT_VIRAL_LOAD_UPTAKE_BY_AGE_FETCH = 'CT_VIRAL_LOAD_UPTAKE_BY_AGE_FETCH'
export const CT_VIRAL_LOAD_UPTAKE_BY_AGE_REQUEST_FAILED = 'CT_VIRAL_LOAD_UPTAKE_BY_AGE_REQUEST_FAILED'
export const CT_VIRAL_LOAD_UPTAKE_BY_COUNTY_REQUEST = 'CT_VIRAL_LOAD_UPTAKE_BY_COUNTY_REQUEST'
export const CT_VIRAL_LOAD_UPTAKE_BY_COUNTY_FETCH = 'CT_VIRAL_LOAD_UPTAKE_BY_COUNTY_FETCH'
export const CT_VIRAL_LOAD_UPTAKE_BY_COUNTY_REQUEST_FAILED = 'CT_VIRAL_LOAD_UPTAKE_BY_COUNTY_REQUEST_FAILED'
export const CT_VIRAL_LOAD_UPTAKE_BY_PARTNER_REQUEST = 'CT_VIRAL_LOAD_UPTAKE_BY_PARTNER_REQUEST'
export const CT_VIRAL_LOAD_UPTAKE_BY_PARTNER_FETCH = 'CT_VIRAL_LOAD_UPTAKE_BY_PARTNER_FETCH'
export const CT_VIRAL_LOAD_UPTAKE_BY_PARTNER_REQUEST_FAILED = 'CT_VIRAL_LOAD_UPTAKE_BY_PARTNER_REQUEST_FAILED'
export const CT_VIRAL_LOAD_UPTAKE_U_TO_U_REQUEST = 'CT_VIRAL_LOAD_UPTAKE_U_TO_U_REQUEST'
export const CT_VIRAL_LOAD_UPTAKE_U_TO_U_FETCH = 'CT_VIRAL_LOAD_UPTAKE_U_TO_U_FETCH'
export const CT_VIRAL_LOAD_UPTAKE_U_TO_U_FAILED = 'CT_VIRAL_LOAD_UPTAKE_U_TO_U_FAILED'
export const CT_VIRAL_LOAD_CATEGORIZATION_U_TO_U_REQUEST = 'CT_VIRAL_LOAD_CATEGORIZATION_U_TO_U_REQUEST'
export const CT_VIRAL_LOAD_CATEGORIZATION_U_TO_U_FETCH = 'CT_VIRAL_LOAD_CATEGORIZATION_U_TO_U_FETCH'
export const CT_VIRAL_LOAD_CATEGORIZATION_U_TO_U_FAILED = 'CT_VIRAL_LOAD_CATEGORIZATION_U_TO_U_FAILED'

export const CT_VIRAL_LOAD_OUTCOMES_BY_SEX_REQUEST = 'CT_VIRAL_LOAD_OUTCOMES_BY_SEX_REQUEST'
export const CT_VIRAL_LOAD_OUTCOMES_BY_SEX_FETCH = 'CT_VIRAL_LOAD_OUTCOMES_BY_SEX_FETCH'
export const CT_VIRAL_LOAD_OUTCOMES_BY_SEX_REQUEST_FAILED = 'CT_VIRAL_LOAD_OUTCOMES_BY_SEX_REQUEST_FAILED'

export const CT_VIRAL_LOAD_6_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_REQUEST = 'CT_VIRAL_LOAD_6_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_REQUEST'
export const CT_VIRAL_LOAD_6_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_FETCH = 'CT_VIRAL_LOAD_6_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_FETCH'
export const CT_VIRAL_LOAD_6_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_FAILED = 'CT_VIRAL_LOAD_6_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_FAILED'

export const CT_VIRAL_LOAD_12_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_REQUEST = 'CT_VIRAL_LOAD_12_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_REQUEST'
export const CT_VIRAL_LOAD_12_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_FETCH = 'CT_VIRAL_LOAD_12_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_FETCH'
export const CT_VIRAL_LOAD_12_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_FAILED = 'CT_VIRAL_LOAD_12_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_FAILED'

export const CT_VIRAL_LOAD_24_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_REQUEST = 'CT_VIRAL_LOAD_24_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_REQUEST'
export const CT_VIRAL_LOAD_24_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_FETCH = 'CT_VIRAL_LOAD_24_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_FETCH'
export const CT_VIRAL_LOAD_24_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_FAILED = 'CT_VIRAL_LOAD_24_MONTH_SUPPRESSION_BY_YEAR_OF_ART_START_FAILED'

export const CT_VIRAL_LOAD_SUPPRESSION_BY_AGE_REQUEST = 'CT_VIRAL_LOAD_SUPPRESSION_BY_AGE_REQUEST'
export const CT_VIRAL_LOAD_SUPPRESSION_BY_AGE_FETCH = 'CT_VIRAL_LOAD_SUPPRESSION_BY_AGE_FETCH'
export const CT_VIRAL_LOAD_SUPPRESSION_BY_AGE_REQUEST_FAILED = 'CT_VIRAL_LOAD_SUPPRESSION_BY_AGE_REQUEST_FAILED'
export const CT_VIRAL_LOAD_SUPPRESSION_BY_REGIMEN_REQUEST = 'CT_VIRAL_LOAD_SUPPRESSION_BY_REGIMEN_REQUEST'
export const CT_VIRAL_LOAD_SUPPRESSION_BY_REGIMEN_FETCH = 'CT_VIRAL_LOAD_SUPPRESSION_BY_REGIMEN_FETCH'
export const CT_VIRAL_LOAD_SUPPRESSION_BY_REGIMEN_REQUEST_FAILED = 'CT_VIRAL_LOAD_SUPPRESSION_BY_REGIMEN_REQUEST_FAILED'
export const CT_VIRAL_LOAD_SUPPRESSION_BY_YEAR_REQUEST = 'CT_VIRAL_LOAD_SUPPRESSION_BY_YEAR_REQUEST'
export const CT_VIRAL_LOAD_SUPPRESSION_BY_YEAR_FETCH = 'CT_VIRAL_LOAD_SUPPRESSION_BY_YEAR_FETCH'
export const CT_VIRAL_LOAD_SUPPRESSION_BY_YEAR_REQUEST_FAILED = 'CT_VIRAL_LOAD_SUPPRESSION_BY_YEAR_REQUEST_FAILED'
export const CT_VIRAL_LOAD_SUPPRESSION_BY_YEAR_AND_SUPPRESSION_CATEGORY_REQUEST = 'CT_VIRAL_LOAD_SUPPRESSION_BY_YEAR_AND_SUPPRESSION_CATEGORY_REQUEST'
export const CT_VIRAL_LOAD_SUPPRESSION_BY_YEAR_AND_SUPPRESSION_CATEGORY_FETCH = 'CT_VIRAL_LOAD_SUPPRESSION_BY_YEAR_AND_SUPPRESSION_CATEGORY_FETCH'
export const CT_VIRAL_LOAD_SUPPRESSION_BY_YEAR_AND_SUPPRESSION_CATEGORY_REQUEST_FAILED = 'CT_VIRAL_LOAD_SUPPRESSION_BY_YEAR_AND_SUPPRESSION_CATEGORY_REQUEST_FAILED'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_FACILITY_REQUEST = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_FACILITY_REQUEST'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_FACILITY_FETCH = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_FACILITY_FETCH'
export const CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_FACILITY_REQUEST_FAILED = 'CT_VIRAL_LOAD_OVERALL_UPTAKE_SUPPRESSION_BY_FACILITY_REQUEST_FAILED'
export const CT_VIRAL_LOAD_OUTCOMES_HVL_BY_FACILITY_REQUEST = 'CT_VIRAL_LOAD_OUTCOMES_HVL_BY_FACILITY_REQUEST'
export const CT_VIRAL_LOAD_OUTCOMES_HVL_BY_FACILITY_FETCH = 'CT_VIRAL_LOAD_OUTCOMES_HVL_BY_FACILITY_FETCH'
export const CT_VIRAL_LOAD_OUTCOMES_HVL_BY_FACILITY_REQUEST_FAILED = 'CT_VIRAL_LOAD_OUTCOMES_HVL_BY_FACILITY_REQUEST_FAILED'

export const CT_TREATMENT_OUTCOMES_OVERALL_LAST_12M_REQUEST = 'CT_TREATMENT_OUTCOMES_OVERALL_LAST_12M_REQUEST'
export const CT_TREATMENT_OUTCOMES_OVERALL_LAST_12M_FETCH = 'CT_TREATMENT_OUTCOMES_OVERALL_LAST_12M_FETCH'
export const CT_TREATMENT_OUTCOMES_OVERALL_LAST_12M_REQUEST_FAILED = 'CT_TREATMENT_OUTCOMES_OVERALL_LAST_12M_REQUEST_FAILED'
export const CT_TREATMENT_OUTCOMES_BY_SEX_REQUEST = 'CT_TREATMENT_OUTCOMES_BY_SEX_REQUEST'
export const CT_TREATMENT_OUTCOMES_BY_SEX_FETCH = 'CT_TREATMENT_OUTCOMES_BY_SEX_FETCH'
export const CT_TREATMENT_OUTCOMES_BY_SEX_REQUEST_FAILED = 'CT_TREATMENT_OUTCOMES_BY_SEX_REQUEST_FAILED'
export const CT_TREATMENT_OUTCOMES_BY_POPULATION_TYPE_REQUEST = 'CT_TREATMENT_OUTCOMES_BY_POPULATION_TYPE_REQUEST'
export const CT_TREATMENT_OUTCOMES_BY_POPULATION_TYPE_FETCH = 'CT_TREATMENT_OUTCOMES_BY_POPULATION_TYPE_FETCH'
export const CT_TREATMENT_OUTCOMES_BY_POPULATION_TYPE_REQUEST_FAILED = 'CT_TREATMENT_OUTCOMES_BY_POPULATION_TYPE_REQUEST_FAILED'
export const CT_TREATMENT_OUTCOMES_BY_AGE_REQUEST = 'CT_TREATMENT_OUTCOMES_BY_AGE_REQUEST'
export const CT_TREATMENT_OUTCOMES_BY_AGE_FETCH = 'CT_TREATMENT_OUTCOMES_BY_AGE_FETCH'
export const CT_TREATMENT_OUTCOMES_BY_AGE_REQUEST_FAILED = 'CT_TREATMENT_OUTCOMES_BY_AGE_REQUEST_FAILED'
export const CT_TREATMENT_OUTCOMES_BY_YEAR_REQUEST = 'CT_TREATMENT_OUTCOMES_BY_YEAR_REQUEST'
export const CT_TREATMENT_OUTCOMES_BY_YEAR_FETCH = 'CT_TREATMENT_OUTCOMES_BY_YEAR_FETCH'
export const CT_TREATMENT_OUTCOMES_BY_YEAR_REQUEST_FAILED = 'CT_TREATMENT_OUTCOMES_BY_YEAR_REQUEST_FAILED'
export const CT_TREATMENT_OUTCOMES_BY_FACILITY_REQUEST = 'CT_TREATMENT_OUTCOMES_BY_FACILITY_REQUEST'
export const CT_TREATMENT_OUTCOMES_BY_FACILITY_FETCH = 'CT_TREATMENT_OUTCOMES_BY_FACILITY_FETCH'
export const CT_TREATMENT_OUTCOMES_BY_FACILITY_REQUEST_FAILED = 'CT_TREATMENT_OUTCOMES_BY_FACILITY_REQUEST_FAILED'
export const CT_THREE_MONTH_RETENTION_REQUEST = 'CT_THREE_MONTH_RETENTION_REQUEST'
export const CT_THREE_MONTH_RETENTION_FETCH = 'CT_THREE_MONTH_RETENTION_FETCH'
export const CT_THREE_MONTH_RETENTION_REQUEST_FAILED = 'CT_THREE_MONTH_RETENTION_REQUEST_FAILED'
export const CT_SIX_MONTH_RETENTION_REQUEST = 'CT_SIX_MONTH_RETENTION_REQUEST'
export const CT_SIX_MONTH_RETENTION_FETCH = 'CT_SIX_MONTH_RETENTION_FETCH'
export const CT_SIX_MONTH_RETENTION_REQUEST_FAILED = 'CT_SIX_MONTH_RETENTION_REQUEST_FAILED'
export const CT_TWELVE_MONTH_RETENTION_REQUEST = 'CT_TWELVE_MONTH_RETENTION_REQUEST'
export const CT_TWELVE_MONTH_RETENTION_FETCH = 'CT_TWELVE_MONTH_RETENTION_FETCH'
export const CT_TWELVE_MONTH_RETENTION_REQUEST_FAILED = 'CT_TWELVE_MONTH_RETENTION_REQUEST_FAILED'
export const CT_TWENTY_FOUR_MONTH_RETENTION_REQUEST = 'CT_TWENTY_FOUR_MONTH_RETENTION_REQUEST'
export const CT_TWENTY_FOUR_MONTH_RETENTION_FETCH = 'CT_TWENTY_FOUR_MONTH_RETENTION_FETCH'
export const CT_TWENTY_FOUR_MONTH_RETENTION_REQUEST_FAILED = 'CT_TWENTY_FOUR_MONTH_RETENTION_REQUEST_FAILED'
export const CT_TREATMENT_OUTCOMES_UNDOCUMENTED_BY_FACILITY_REQUEST = 'CT_TREATMENT_OUTCOMES_UNDOCUMENTED_BY_FACILITY_REQUEST'
export const CT_TREATMENT_OUTCOMES_UNDOCUMENTED_BY_FACILITY_FETCH = 'CT_TREATMENT_OUTCOMES_UNDOCUMENTED_BY_FACILITY_FETCH'
export const CT_TREATMENT_OUTCOMES_UNDOCUMENTED_BY_FACILITY_REQUEST_FAILED = 'CT_TREATMENT_OUTCOMES_UNDOCUMENTED_BY_FACILITY_REQUEST_FAILED'
export const CT_MISSING_DIAGNOSIS_DATE_BY_FACILITY_REQUEST = 'CT_MISSING_DIAGNOSIS_DATE_BY_FACILITY_REQUEST'
export const CT_MISSING_DIAGNOSIS_DATE_BY_FACILITY_FETCH = 'CT_MISSING_DIAGNOSIS_DATE_BY_FACILITY_FETCH'
export const CT_MISSING_DIAGNOSIS_DATE_BY_FACILITY_REQUEST_FAILED = 'CT_MISSING_DIAGNOSIS_DATE_BY_FACILITY_REQUEST_FAILED'

export const CT_APPOINTMENT_KEEPING_WATERFALL_REQUEST = 'CT_APPOINTMENT_KEEPING_WATERFALL_REQUEST';
export const CT_APPOINTMENT_KEEPING_WATERFALL_FETCH = 'CT_APPOINTMENT_KEEPING_WATERFALL_FETCH';
export const CT_APPOINTMENT_KEEPING_WATERFALL_FAILED = 'CT_APPOINTMENT_KEEPING_WATERFALL_FAILED';

export const CT_QUATERLY_IIT_REQUEST = 'CT_QUATERLY_IIT_REQUEST';
export const CT_QUATERLY_IIT_FETCH = 'CT_QUATERLY_IIT_FETCH';
export const CT_QUATERLY_IIT_FAILED = 'CT_QUATERLY_IIT_FAILED';

export const CT_IIT_TRACING_REQUEST = 'CT_IIT_TRACING_REQUEST';
export const CT_IIT_TRACING_FETCH = 'CT_IIT_TRACING_FETCH';
export const CT_IIT_TRACING_FAILED = 'CT_IIT_TRACING_FAILED';

export const CT_IIT_TRACING_OUTCOMES_REQUEST = 'CT_IIT_TRACING_OUTCOMES_REQUEST';
export const CT_IIT_TRACING_OUTCOMES_FETCH = 'CT_IIT_TRACING_OUTCOMES_FETCH';
export const CT_IIT_TRACING_OUTCOMES_FAILED = 'CT_IIT_TRACING_OUTCOMES_FAILED';

export const HRH_PRACTITIONERS_COUNT_BY_COUNTY_QUALIFICATION_REQUEST = 'HRH_PRACTITIONERS_COUNT_BY_COUNTY_QUALIFICATION_REQUEST'
export const HRH_PRACTITIONERS_COUNT_BY_COUNTY_QUALIFICATION_FETCH = 'HRH_PRACTITIONERS_COUNT_BY_COUNTY_QUALIFICATION_FETCH'
export const HRH_PRACTITIONERS_COUNT_BY_COUNTY_QUALIFICATION_REQUEST_FAILED = 'HRH_PRACTITIONERS_COUNT_BY_COUNTY_QUALIFICATION_REQUEST_FAILED'

export const HRH_POPULATION_BY_COUNTY_REQUEST = 'HRH_POPULATION_BY_COUNTY_REQUEST'
export const HRH_POPULATION_BY_COUNTY_FETCH = 'HRH_POPULATION_BY_COUNTY_FETCH'
export const HRH_POPULATION_BY_COUNTY_REQUEST_FAILED = 'HRH_POPULATION_BY_COUNTY_REQUEST_FAILED'

export const HTS_UPTAKE_BY_SEX_REQUEST = 'HTS_UPTAKE_BY_SEX_REQUEST'
export const HTS_UPTAKE_BY_SEX_FETCH = 'HTS_UPTAKE_BY_SEX_FETCH'
export const HTS_UPTAKE_BY_SEX_REQUEST_FAILED = 'HTS_UPTAKE_BY_SEX_REQUEST_FAILED'

export const HTS_UPTAKE_BY_COUNTY_REQUEST = 'HTS_UPTAKE_BY_COUNTY_REQUEST'
export const HTS_UPTAKE_BY_COUNTY_FETCH = 'HTS_UPTAKE_BY_COUNTY_FETCH'
export const HTS_UPTAKE_BY_COUNTY_REQUEST_FAILED = 'HTS_UPTAKE_BY_COUNTY_REQUEST_FAILED'

export const RR_OVERALL_REPORTING_RATES_BY_FACILITY_REPORTED_REQUEST = 'RR_OVERALL_REPORTING_RATES_BY_FACILITY_REPORTED_REQUEST'
export const RR_OVERALL_REPORTING_RATES_BY_FACILITY_REPORTED_FETCH = 'RR_OVERALL_REPORTING_RATES_BY_FACILITY_REPORTED_FETCH'
export const RR_OVERALL_REPORTING_RATES_BY_FACILITY_REPORTED_REQUEST_FAILED = 'RR_OVERALL_REPORTING_RATES_BY_FACILITY_REPORTED_REQUEST_FAILED'

export const HIS_FACILITY_STATUS_REQUEST = 'HIS_FACILITY_STATUS_REQUEST'
export const HIS_FACILITY_STATUS_FETCH = 'HIS_FACILITY_STATUS_FETCH'
export const HIS_FACILITY_STATUS_FAILED = 'HIS_FACILITY_STATUS_FAILED'

export const HIS_FACILITY_STATUS_BY_PARTNER_REQUEST = 'HIS_FACILITY_STATUS_BY_PARTNER_REQUEST'
export const HIS_FACILITY_STATUS_BY_PARTNER_FETCH = 'HIS_FACILITY_STATUS_BY_PARTNER_FETCH'
export const HIS_FACILITY_STATUS_BY_PARTNER_FAILED = 'HIS_FACILITY_STATUS_BY_PARTNER_FAILED'

export const HIS_FACILITY_STATUS_BY_COUNTY_REQUEST = 'HIS_FACILITY_STATUS_BY_COUNTY_REQUEST'
export const HIS_FACILITY_STATUS_BY_COUNTY_FETCH = 'HIS_FACILITY_STATUS_BY_COUNTY_FETCH'
export const HIS_FACILITY_STATUS_BY_COUNTY_FAILED = 'HIS_FACILITY_STATUS_BY_COUNTY_FAILED'

export const HIS_FACILITY_LEVEL_BY_PARTNER_REQUEST = 'HIS_FACILITY_LEVEL_BY_PARTNER_REQUEST'
export const HIS_FACILITY_LEVEL_BY_PARTNER_FETCH = 'HIS_FACILITY_LEVEL_BY_PARTNER_FETCH'
export const HIS_FACILITY_LEVEL_BY_PARTNER_FAILED = 'HIS_FACILITY_LEVEL_BY_PARTNER_FAILED'

export const HIS_FACILITY_LEVEL_BY_COUNTY_REQUEST = 'HIS_FACILITY_LEVEL_BY_COUNTY_REQUEST'
export const HIS_FACILITY_LEVEL_BY_COUNTY_FETCH = 'HIS_FACILITY_LEVEL_BY_COUNTY_FETCH'
export const HIS_FACILITY_LEVEL_BY_COUNTY_FAILED = 'HIS_FACILITY_LEVEL_BY_COUNTY_FAILED'

export const HIS_FACILITY_BY_INFRASTRUCTURE_REQUEST = 'HIS_FACILITY_BY_INFRASTRUCTURE_REQUEST'
export const HIS_FACILITY_BY_INFRASTRUCTURE_FETCH = 'HIS_FACILITY_BY_INFRASTRUCTURE_FETCH'
export const HIS_FACILITY_BY_INFRASTRUCTURE_FAILED = 'HIS_FACILITY_BY_INFRASTRUCTURE_FAILED'

export const HIS_FACILITY_BY_INFRASTRUCTURE_COUNTY_REQUEST = 'HIS_FACILITY_BY_INFRASTRUCTURE_COUNTY_REQUEST'
export const HIS_FACILITY_BY_INFRASTRUCTURE_COUNTY_FETCH = 'HIS_FACILITY_BY_INFRASTRUCTURE_COUNTY_FETCH'
export const HIS_FACILITY_BY_INFRASTRUCTURE_COUNTY_FAILED = 'HIS_FACILITY_BY_INFRASTRUCTURE_COUNTY_FAILED'

export const HIS_FACILITY_LINELIST_REQUEST = 'HIS_FACILITY_LINELIST_REQUEST'
export const HIS_FACILITY_LINELIST_FETCH = 'HIS_FACILITY_LINELIST_FETCH'
export const HIS_FACILITY_LINELIST_FAILED = 'HIS_FACILITY_LINELIST_FAILED'

export const HIS_FACILITY_ART_HTS_MNCH_REQUEST = 'HIS_FACILITY_ART_HTS_MNCH_REQUEST'
export const HIS_FACILITY_ART_HTS_MNCH_FETCH = 'HIS_FACILITY_ART_HTS_MNCH_FETCH'
export const HIS_FACILITY_ART_HTS_MNCH_FAILED = 'HIS_FACILITY_ART_HTS_MNCH_FAILED'

export const HIS_FACILITY_TXCURR_REQUEST = 'HIS_FACILITY_TXCURR_REQUEST'
export const HIS_FACILITY_TXCURR_FETCH = 'HIS_FACILITY_TXCURR_FETCH'
export const HIS_FACILITY_TXCURR_FAILED = 'HIS_FACILITY_TXCURR_FAILED'

export const RR_OVERALL_REPORTING_RATES_BY_FACILITY_NOT_REPORTED_REQUEST = 'RR_OVERALL_REPORTING_RATES_BY_FACILITY_NOT_REPORTED_REQUEST'
export const RR_OVERALL_REPORTING_RATES_BY_FACILITY_NOT_REPORTED_FETCH = 'RR_OVERALL_REPORTING_RATES_BY_FACILITY_NOT_REPORTED_FETCH'
export const RR_OVERALL_REPORTING_RATES_BY_FACILITY_NOT_REPORTED_REQUEST_FAILED = 'RR_OVERALL_REPORTING_RATES_BY_FACILITY_NOT_REPORTED_REQUEST_FAILED'

export const RR_CONSISTENCY_BY_FACILITY_NOT_REPORTED_REQUEST = 'RR_CONSISTENCY_BY_FACILITY_NOT_REPORTED_REQUEST'
export const RR_CONSISTENCY_BY_FACILITY_NOT_REPORTED_FETCH = 'RR_CONSISTENCY_BY_FACILITY_NOT_REPORTED_FETCH'
export const RR_CONSISTENCY_BY_FACILITY_NOT_REPORTED_REQUEST_FAILED = 'RR_CONSISTENCY_BY_FACILITY_NOT_REPORTED_REQUEST_FAILED'


export const CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_SEX_REQUEST = 'CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_SEX_REQUEST'
export const CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_SEX_FETCH = 'CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_SEX_FETCH'
export const CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_SEX_FAILED = 'CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_SEX_FAILED'
export const CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_AGE_REQUEST = 'CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_AGE_REQUEST'
export const CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_AGE_FETCH = 'CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_AGE_FETCH'
export const CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_AGE_FAILED = 'CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_AGE_FAILED'
export const CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_COUNTY_REQUEST = 'CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_COUNTY_REQUEST'
export const CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_COUNTY_FETCH = 'CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_COUNTY_FETCH'
export const CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_COUNTY_FAILED = 'CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_COUNTY_FAILED'
export const CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_PARTNER_REQUEST = 'CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_PARTNER_REQUEST'
export const CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_PARTNER_FETCH = 'CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_PARTNER_FETCH'
export const CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_PARTNER_FAILED = 'CT_OTZ_ENROLLMENT_AMONG_ALHIV_ON_ART_BY_PARTNER_FAILED'
export const CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_SEX_REQUEST = 'CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_SEX_REQUEST'
export const CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_SEX_FETCH = 'CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_SEX_FETCH'
export const CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_SEX_FAILED = 'CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_SEX_FAILED'
export const CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_AGE_REQUEST = 'CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_AGE_REQUEST'
export const CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_AGE_FETCH = 'CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_AGE_FETCH'
export const CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_AGE_FAILED = 'CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_AGE_FAILED'
export const CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_COUNTY_REQUEST = 'CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_COUNTY_REQUEST'
export const CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_COUNTY_FETCH = 'CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_COUNTY_FETCH'
export const CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_COUNTY_FAILED = 'CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_COUNTY_FAILED'
export const CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_PARTNER_REQUEST = 'CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_PARTNER_REQUEST'
export const CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_PARTNER_FETCH = 'CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_PARTNER_FETCH'
export const CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_PARTNER_FAILED = 'CT_OTZ_VL_UPTAKE_AMONG_ALHIV_ENROLLED_IN_OTZ_BY_PARTNER_FAILED'
export const CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_REQUEST = 'CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_REQUEST'
export const CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_FETCH = 'CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_FETCH'
export const CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_FAILED = 'CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_FAILED'
export const CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_BY_COUNTY_REQUEST = 'CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_BY_COUNTY_REQUEST'
export const CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_BY_COUNTY_FETCH = 'CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_BY_COUNTY_FETCH'
export const CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_BY_COUNTY_FAILED = 'CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_BY_COUNTY_FAILED'
export const CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_BY_PARTNER_REQUEST = 'CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_BY_PARTNER_REQUEST'
export const CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_BY_PARTNER_FETCH = 'CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_BY_PARTNER_FETCH'
export const CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_BY_PARTNER_FAILED = 'CT_OTZ_PROPORTION_OF_ALHIV_ENROLLED_IN_OTZ_WHO_HAVE_COMPLETED_OTZ_TRAINING_BY_PARTNER_FAILED'
export const CT_OTZ_OUTCOMES_AMONG_ALHIV_WITH_BASELINE_VL_REQUEST = 'CT_OTZ_OUTCOMES_AMONG_ALHIV_WITH_BASELINE_VL_REQUEST'
export const CT_OTZ_OUTCOMES_AMONG_ALHIV_WITH_BASELINE_VL_FETCH = 'CT_OTZ_OUTCOMES_AMONG_ALHIV_WITH_BASELINE_VL_FETCH'
export const CT_OTZ_OUTCOMES_AMONG_ALHIV_WITH_BASELINE_VL_FAILED = 'CT_OTZ_OUTCOMES_AMONG_ALHIV_WITH_BASELINE_VL_FAILED'
export const CT_OTZ_OUTCOMES_AMONG_ALHIV_WITH_RE_SUPPRESSION_REQUEST = 'CT_OTZ_OUTCOMES_AMONG_ALHIV_WITH_RE_SUPPRESSION_REQUEST'
export const CT_OTZ_OUTCOMES_AMONG_ALHIV_WITH_RE_SUPPRESSION_FETCH = 'CT_OTZ_OUTCOMES_AMONG_ALHIV_WITH_RE_SUPPRESSION_FETCH'
export const CT_OTZ_OUTCOMES_AMONG_ALHIV_WITH_RE_SUPPRESSION_FAILED = 'CT_OTZ_OUTCOMES_AMONG_ALHIV_WITH_RE_SUPPRESSION_FAILED'
export const CT_OTZ_OUTCOMES_BY_GENDER_REQUEST = 'CT_OTZ_OUTCOMES_BY_GENDER_REQUEST'
export const CT_OTZ_OUTCOMES_BY_GENDER_FETCH = 'CT_OTZ_OUTCOMES_BY_GENDER_FETCH'
export const CT_OTZ_OUTCOMES_BY_GENDER_FAILED = 'CT_OTZ_OUTCOMES_BY_GENDER_FAILED'
export const CT_OTZ_OUTCOMES_BY_POPULATION_TYPE_REQUEST = 'CT_OTZ_OUTCOMES_BY_POPULATION_TYPE_REQUEST'
export const CT_OTZ_OUTCOMES_BY_POPULATION_TYPE_FETCH = 'CT_OTZ_OUTCOMES_BY_POPULATION_TYPE_FETCH'
export const CT_OTZ_OUTCOMES_BY_POPULATION_TYPE_FAILED = 'CT_OTZ_OUTCOMES_BY_POPULATION_TYPE_FAILED'
export const CT_OTZ_OUTCOMES_BY_AGE_GROUP_REQUEST = 'CT_OTZ_OUTCOMES_BY_AGE_GROUP_REQUEST'
export const CT_OTZ_OUTCOMES_BY_AGE_GROUP_FETCH = 'CT_OTZ_OUTCOMES_BY_AGE_GROUP_FETCH'
export const CT_OTZ_OUTCOMES_BY_AGE_GROUP_FAILED = 'CT_OTZ_OUTCOMES_BY_AGE_GROUP_FAILED'
export const CT_OTZ_OUTCOMES_BY_YEAR_OF_ART_START_REQUEST = 'CT_OTZ_OUTCOMES_BY_YEAR_OF_ART_START_REQUEST'
export const CT_OTZ_OUTCOMES_BY_YEAR_OF_ART_START_FETCH = 'CT_OTZ_OUTCOMES_BY_YEAR_OF_ART_START_FETCH'
export const CT_OTZ_OUTCOMES_BY_YEAR_OF_ART_START_FAILED = 'CT_OTZ_OUTCOMES_BY_YEAR_OF_ART_START_FAILED'
export const CT_OTZ_OUTCOMES_BY_COUNTY_REQUEST = 'CT_OTZ_OUTCOMES_BY_COUNTY_REQUEST'
export const CT_OTZ_OUTCOMES_BY_COUNTY_FETCH = 'CT_OTZ_OUTCOMES_BY_COUNTY_FETCH'
export const CT_OTZ_OUTCOMES_BY_COUNTY_FAILED = 'CT_OTZ_OUTCOMES_BY_COUNTY_FAILED'
export const CT_OTZ_OUTCOMES_BY_PARTNER_REQUEST = 'CT_OTZ_OUTCOMES_BY_PARTNER_REQUEST'
export const CT_OTZ_OUTCOMES_BY_PARTNER_FETCH = 'CT_OTZ_OUTCOMES_BY_PARTNER_FETCH'
export const CT_OTZ_OUTCOMES_BY_PARTNER_FAILED = 'CT_OTZ_OUTCOMES_BY_PARTNER_FAILED'
export const CT_OTZ_TOTAL_ADOLESCENTS_REQUEST = 'CT_OTZ_TOTAL_ADOLESCENTS_REQUEST'
export const CT_OTZ_TOTAL_ADOLESCENTS_FETCH = 'CT_OTZ_TOTAL_ADOLESCENTS_FETCH'
export const CT_OTZ_TOTAL_ADOLESCENTS_FAILED = 'CT_OTZ_TOTAL_ADOLESCENTS_FAILED'
export const CT_OTZ_ENROLLED_REQUEST = 'CT_OTZ_ENROLLED_REQUEST'
export const CT_OTZ_ENROLLED_FETCH = 'CT_OTZ_ENROLLED_FETCH'
export const CT_OTZ_ENROLLED_FAILED = 'CT_OTZ_ENROLLED_FAILED'
export const CT_OTZ_TOTAL_WITH_VL_RESULTS_REQUEST = 'CT_OTZ_TOTAL_WITH_VL_RESULTS_REQUEST'
export const CT_OTZ_TOTAL_WITH_VL_RESULTS_FETCH = 'CT_OTZ_TOTAL_WITH_VL_RESULTS_FETCH'
export const CT_OTZ_TOTAL_WITH_VL_RESULTS_FAILED = 'CT_OTZ_TOTAL_WITH_VL_RESULTS_FAILED'

export const CT_OTZ_TOTAL_WITH_DURABLE_VL_RESULTS_REQUEST = 'CT_OTZ_TOTAL_WITH_DURABLE_VL_RESULTS_REQUEST'
export const CT_OTZ_TOTAL_WITH_DURABLE_VL_RESULTS_FETCH = 'CT_OTZ_TOTAL_WITH_DURABLE_VL_RESULTS_FETCH'
export const CT_OTZ_TOTAL_WITH_DURABLE_VL_RESULTS_FAILED = 'CT_OTZ_TOTAL_WITH_DURABLE_VL_RESULTS_FAILED'

export const CT_OTZ_TOTAL_WITH_VL_RESULTS_LESS_THAN_1000_REQUEST = 'CT_OTZ_TOTAL_WITH_VL_RESULTS_LESS_THAN_1000_REQUEST'
export const CT_OTZ_TOTAL_WITH_VL_RESULTS_LESS_THAN_1000_FETCH = 'CT_OTZ_TOTAL_WITH_VL_RESULTS_LESS_THAN_1000_FETCH'
export const CT_OTZ_TOTAL_WITH_VL_RESULTS_LESS_THAN_1000_FAILED = 'CT_OTZ_TOTAL_WITH_VL_RESULTS_LESS_THAN_1000_FAILED'
export const CT_OTZ_ENROLLMENT_TREND_REQUEST = 'CT_OTZ_ENROLLMENT_TREND_REQUEST';
export const CT_OTZ_ENROLLMENT_TREND_FETCH = 'CT_OTZ_ENROLLMENT_TREND_FETCH';
export const CT_OTZ_ENROLLMENT_TREND_FAILED = 'CT_OTZ_ENROLLMENT_TREND_FAILED';

export const CT_OTZ_ENROLLMENT_BY_AGE_SEX_REQUEST = 'CT_OTZ_ENROLLMENT_BY_AGE_SEX_REQUEST';
export const CT_OTZ_ENROLLMENT_BY_AGE_SEX_FETCH = 'CT_OTZ_ENROLLMENT_BY_AGE_SEX_FETCH';
export const CT_OTZ_ENROLLMENT_BY_AGE_SEX_FAILED = 'CT_OTZ_ENROLLMENT_BY_AGE_SEX_FAILED';

export const CT_OTZ_NOT_ENROLLED_BY_PARTNER_REQUEST = 'CT_OTZ_NOT_ENROLLED_BY_PARTNER_REQUEST';
export const CT_OTZ_NOT_ENROLLED_BY_PARTNER_FETCH = 'CT_OTZ_NOT_ENROLLED_BY_PARTNER_FETCH';
export const CT_OTZ_NOT_ENROLLED_BY_PARTNER_FAILED = 'CT_OTZ_NOT_ENROLLED_BY_PARTNER_FAILED';

export const CT_OTZ_NOT_ENROLLED_BY_COUNTY_REQUEST = 'CT_OTZ_NOT_ENROLLED_BY_COUNTY_REQUEST';
export const CT_OTZ_NOT_ENROLLED_BY_COUNTY_FETCH = 'CT_OTZ_NOT_ENROLLED_BY_COUNTY_FETCH';
export const CT_OTZ_NOT_ENROLLED_BY_COUNTY_FAILED = 'CT_OTZ_NOT_ENROLLED_BY_COUNTY_FAILED';

export const CT_OTZ_CALHIV_WITH_RESUPRESSION_REQUEST = 'CT_OTZ_CALHIV_WITH_RESUPRESSION_REQUEST';
export const CT_OTZ_CALHIV_WITH_RESUPRESSION_FETCH = 'CT_OTZ_CALHIV_WITH_RESUPRESSION_FETCH';
export const CT_OTZ_CALHIV_WITH_RESUPRESSION_FAILED = 'CT_OTZ_CALHIV_WITH_RESUPRESSION_FAILED';

export const CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_REQUEST = 'CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_REQUEST';
export const CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_FETCH = 'CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_FETCH';
export const CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_FAILED = 'CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_FAILED';

export const CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_SEX_REQUEST = 'CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_SEX_REQUEST';
export const CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_SEX_FETCH = 'CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_SEX_FETCH';
export const CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_SEX_FAILED = 'CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_SEX_FAILED';

export const CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_COUNTY_REQUEST = 'CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_COUNTY_REQUEST';
export const CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_COUNTY_FETCH = 'CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_COUNTY_FETCH';
export const CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_COUNTY_FAILED = 'CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_COUNTY_FAILED';

export const CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_PARTNER_REQUEST = 'CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_PARTNER_REQUEST';
export const CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_PARTNER_FETCH = 'CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_PARTNER_FETCH';
export const CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_PARTNER_FAILED = 'CT_OTZ_VL_SUPPRESSION_NOT_ENROLLED_BY_PARTNER_FAILED';

export const CT_OTZ_ALHIV_ON_ART_BY_AGE_SEX_REQUEST = 'CT_OTZ_ALHIV_ON_ART_BY_AGE_SEX_REQUEST';
export const CT_OTZ_ALHIV_ON_ART_BY_AGE_SEX_FETCH = 'CT_OTZ_ALHIV_ON_ART_BY_AGE_SEX_FETCH';
export const CT_OTZ_ALHIV_ON_ART_BY_AGE_SEX_FAILED = 'CT_OTZ_ALHIV_ON_ART_BY_AGE_SEX_FAILED';

export const CT_OVC_OVERALL_SERV_REQUEST = 'CT_OVC_OVERALL_SERV_REQUEST'
export const CT_OVC_OVERALL_SERV_FETCH = 'CT_OVC_OVERALL_SERV_FETCH'
export const CT_OVC_OVERALL_SERV_FAILED = 'CT_OVC_OVERALL_SERV_FAILED'

export const CT_OVC_SERV_BY_GENDER_REQUEST = 'CT_OVC_SERV_BY_GENDER_REQUEST'
export const CT_OVC_SERV_BY_GENDER_FETCH = 'CT_OVC_SERV_BY_GENDER_FETCH'
export const CT_OVC_SERV_BY_GENDER_FAILED = 'CT_OVC_SERV_BY_GENDER_FAILED'
export const CT_OVC_CAREGIVERS_RELATIONSHIP_TO_OVC_CLIENT_REQUEST = 'CT_OVC_CAREGIVERS_RELATIONSHIP_TO_OVC_CLIENT_REQUEST'
export const CT_OVC_CAREGIVERS_RELATIONSHIP_TO_OVC_CLIENT_FETCH = 'CT_OVC_CAREGIVERS_RELATIONSHIP_TO_OVC_CLIENT_FETCH'
export const CT_OVC_CAREGIVERS_RELATIONSHIP_TO_OVC_CLIENT_FAILED = 'CT_OVC_CAREGIVERS_RELATIONSHIP_TO_OVC_CLIENT_FAILED'
export const CT_OVC_PROPORTION_OF_CLIENTS_IN_CPIMS_OVERALL_REQUEST = 'CT_OVC_PROPORTION_OF_CLIENTS_IN_CPIMS_OVERALL_REQUEST'
export const CT_OVC_PROPORTION_OF_CLIENTS_IN_CPIMS_OVERALL_FETCH = 'CT_OVC_PROPORTION_OF_CLIENTS_IN_CPIMS_OVERALL_FETCH'
export const CT_OVC_PROPORTION_OF_CLIENTS_IN_CPIMS_OVERALL_FAILED = 'CT_OVC_PROPORTION_OF_CLIENTS_IN_CPIMS_OVERALL_FAILED'
export const CT_OVC_PROPORTION_OF_CLIENTS_IN_CPIMS_BY_GENDER_REQUEST = 'CT_OVC_PROPORTION_OF_CLIENTS_IN_CPIMS_BY_GENDER_REQUEST'
export const CT_OVC_PROPORTION_OF_CLIENTS_IN_CPIMS_BY_GENDER_FETCH = 'CT_OVC_PROPORTION_OF_CLIENTS_IN_CPIMS_BY_GENDER_FETCH'
export const CT_OVC_PROPORTION_OF_CLIENTS_IN_CPIMS_BY_GENDER_FAILED = 'CT_OVC_PROPORTION_OF_CLIENTS_IN_CPIMS_BY_GENDER_FAILED'
export const CT_OVC_SERV_DISTRIBUTION_BY_COUNTY_REQUEST = 'CT_OVC_SERV_DISTRIBUTION_BY_COUNTY_REQUEST'
export const CT_OVC_SERV_DISTRIBUTION_BY_COUNTY_FETCH = 'CT_OVC_SERV_DISTRIBUTION_BY_COUNTY_FETCH'
export const CT_OVC_SERV_DISTRIBUTION_BY_COUNTY_FAILED = 'CT_OVC_SERV_DISTRIBUTION_BY_COUNTY_FAILED'
export const CT_OVC_SERV_DISTRIBUTION_BY_PARTNER_REQUEST = 'CT_OVC_SERV_DISTRIBUTION_BY_PARTNER_REQUEST'
export const CT_OVC_SERV_DISTRIBUTION_BY_PARTNER_FETCH = 'CT_OVC_SERV_DISTRIBUTION_BY_PARTNER_FETCH'
export const CT_OVC_SERV_DISTRIBUTION_BY_PARTNER_FAILED = 'CT_OVC_SERV_DISTRIBUTION_BY_PARTNER_FAILED'
export const CT_OVC_CLIENTS_EXIT_REASONS_REQUEST = 'CT_OVC_CLIENTS_EXIT_REASONS_REQUEST'
export const CT_OVC_CLIENTS_EXIT_REASONS_FETCH = 'CT_OVC_CLIENTS_EXIT_REASONS_FETCH'
export const CT_OVC_CLIENTS_EXIT_REASONS_FAILED = 'CT_OVC_CLIENTS_EXIT_REASONS_FAILED'
export const CT_OVC_VIRAL_SUPPRESSION_AMONG_OVC_PATIENT_OVERALL_REQUEST = 'CT_OVC_VIRAL_SUPPRESSION_AMONG_OVC_PATIENT_OVERALL_REQUEST'
export const CT_OVC_VIRAL_SUPPRESSION_AMONG_OVC_PATIENT_OVERALL_FETCH = 'CT_OVC_VIRAL_SUPPRESSION_AMONG_OVC_PATIENT_OVERALL_FETCH'
export const CT_OVC_VIRAL_SUPPRESSION_AMONG_OVC_PATIENT_OVERALL_FAILED = 'CT_OVC_VIRAL_SUPPRESSION_AMONG_OVC_PATIENT_OVERALL_FAILED'
export const CT_OVC_VIRAL_SUPPRESSION_AMONG_OVC_PATIENT_BY_GENDER_REQUEST = 'CT_OVC_VIRAL_SUPPRESSION_AMONG_OVC_PATIENT_BY_GENDER_REQUEST'
export const CT_OVC_VIRAL_SUPPRESSION_AMONG_OVC_PATIENT_BY_GENDER_FETCH = 'CT_OVC_VIRAL_SUPPRESSION_AMONG_OVC_PATIENT_BY_GENDER_FETCH'
export const CT_OVC_VIRAL_SUPPRESSION_AMONG_OVC_PATIENT_BY_GENDER_FAILED = 'CT_OVC_VIRAL_SUPPRESSION_AMONG_OVC_PATIENT_BY_GENDER_FAILED'
export const CT_TREATMENT_OUTCOMES_NET_COHORT_REQUEST = 'CT_TREATMENT_OUTCOMES_NET_COHORT_REQUEST'
export const CT_TREATMENT_OUTCOMES_NET_COHORT_FETCH = 'CT_TREATMENT_OUTCOMES_NET_COHORT_FETCH'
export const CT_TREATMENT_OUTCOMES_NET_COHORT_FAILED = 'CT_TREATMENT_OUTCOMES_NET_COHORT_FAILED'
export const CT_OTZ_ENROLLED_ADOLESCENTS_BY_AGE_GROUP_REQUEST = 'CT_OTZ_ENROLLED_ADOLESCENTS_BY_AGE_GROUP_REQUEST'
export const CT_OTZ_ENROLLED_ADOLESCENTS_BY_AGE_GROUP_FETCH = 'CT_OTZ_ENROLLED_ADOLESCENTS_BY_AGE_GROUP_FETCH'
export const CT_OTZ_ENROLLED_ADOLESCENTS_BY_AGE_GROUP_FAILED = 'CT_OTZ_ENROLLED_ADOLESCENTS_BY_AGE_GROUP_FAILED'
export const CT_OTZ_ENROLLED_ADOLESCENTS_BY_COUNTY_REQUEST = 'CT_OTZ_ENROLLED_ADOLESCENTS_BY_COUNTY_REQUEST'
export const CT_OTZ_ENROLLED_ADOLESCENTS_BY_COUNTY_FETCH = 'CT_OTZ_ENROLLED_ADOLESCENTS_BY_COUNTY_FETCH'
export const CT_OTZ_ENROLLED_ADOLESCENTS_BY_COUNTY_FAILED = 'CT_OTZ_ENROLLED_ADOLESCENTS_BY_COUNTY_FAILED'
export const CT_OTZ_ENROLLED_ADOLESCENTS_BY_PARTNER_REQUEST = 'CT_OTZ_ENROLLED_ADOLESCENTS_BY_PARTNER_REQUEST'
export const CT_OTZ_ENROLLED_ADOLESCENTS_BY_PARTNER_FETCH = 'CT_OTZ_ENROLLED_ADOLESCENTS_BY_PARTNER_FETCH'
export const CT_OTZ_ENROLLED_ADOLESCENTS_BY_PARTNER_FAILED = 'CT_OTZ_ENROLLED_ADOLESCENTS_BY_PARTNER_FAILED'

export const CT_PROPORTION_OF_OTZ_COMPLETED_TRAINING_BY_SEX_REQUEST = 'CT_PROPORTION_OF_OTZ_COMPLETED_TRAINING_BY_SEX_REQUEST'
export const CT_PROPORTION_OF_OTZ_COMPLETED_TRAINING_BY_SEX_FETCH = 'CT_PROPORTION_OF_OTZ_COMPLETED_TRAINING_BY_SEX_FETCH'
export const CT_PROPORTION_OF_OTZ_COMPLETED_TRAINING_BY_SEX_FAILED = 'CT_PROPORTION_OF_OTZ_COMPLETED_TRAINING_BY_SEX_FAILED'

export const CT_OTZ_VL_SUPPRESSION_BY_SEX_REQUEST = 'CT_OTZ_VL_SUPPRESSION_BY_SEX_REQUEST'
export const CT_OTZ_VL_SUPPRESSION_BY_SEX_FETCH = 'CT_OTZ_VL_SUPPRESSION_BY_SEX_FETCH'
export const CT_OTZ_VL_SUPPRESSION_BY_SEX_FAILED = 'CT_OTZ_VL_SUPPRESSION_BY_SEX_FAILED'

export const CT_OTZ_VL_SUPPRESSION_BY_AGE_REQUEST = 'CT_OTZ_VL_SUPPRESSION_BY_AGE_REQUEST'
export const CT_OTZ_VL_SUPPRESSION_BY_AGE_FETCH = 'CT_OTZ_VL_SUPPRESSION_BY_AGE_FETCH'
export const CT_OTZ_VL_SUPPRESSION_BY_AGE_FAILED = 'CT_OTZ_VL_SUPPRESSION_BY_AGE_FAILED'

export const CT_OTZ_VL_SUPPRESSION_BY_COUNTY_REQUEST = 'CT_OTZ_VL_SUPPRESSION_BY_COUNTY_REQUEST'
export const CT_OTZ_VL_SUPPRESSION_BY_COUNTY_FETCH = 'CT_OTZ_VL_SUPPRESSION_BY_COUNTY_FETCH'
export const CT_OTZ_VL_SUPPRESSION_BY_COUNTY_FAILED = 'CT_OTZ_VL_SUPPRESSION_BY_COUNTY_FAILED'

export const CT_OTZ_VL_SUPPRESSION_BY_PARTNER_REQUEST = 'CT_OTZ_VL_SUPPRESSION_BY_PARTNER_REQUEST'
export const CT_OTZ_VL_SUPPRESSION_BY_PARTNER_FETCH = 'CT_OTZ_VL_SUPPRESSION_BY_PARTNER_FETCH'
export const CT_OTZ_VL_SUPPRESSION_BY_PARTNER_FAILED = 'CT_OTZ_VL_SUPPRESSION_BY_PARTNER_FAILED'

export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_REQUEST = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_REQUEST'
export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_FETCH = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_FETCH'
export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_FAILED = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_FAILED'
export const CT_COVID_ADULT_PLHIV_PARTIALLY_VACCINATED_REQUEST = 'CT_COVID_ADULT_PLHIV_PARTIALLY_VACCINATED_REQUEST'
export const CT_COVID_ADULT_PLHIV_PARTIALLY_VACCINATED_FETCH = 'CT_COVID_ADULT_PLHIV_PARTIALLY_VACCINATED_FETCH'
export const CT_COVID_ADULT_PLHIV_PARTIALLY_VACCINATED_FAILED = 'CT_COVID_ADULT_PLHIV_PARTIALLY_VACCINATED_FAILED'
export const CT_COVID_ADULT_PLHIV_FULLY_VACCINATED_REQUEST = 'CT_COVID_ADULT_PLHIV_FULLY_VACCINATED_REQUEST'
export const CT_COVID_ADULT_PLHIV_FULLY_VACCINATED_FETCH = 'CT_COVID_ADULT_PLHIV_FULLY_VACCINATED_FETCH'
export const CT_COVID_ADULT_PLHIV_FULLY_VACCINATED_FAILED = 'CT_COVID_ADULT_PLHIV_FULLY_VACCINATED_FAILED'
export const CT_COVID_ADULT_PLHIV_VACCINATED_BY_AGE_GROUP_REQUEST = 'CT_COVID_ADULT_PLHIV_VACCINATED_BY_AGE_GROUP_REQUEST'
export const CT_COVID_ADULT_PLHIV_VACCINATED_BY_AGE_GROUP_FETCH = 'CT_COVID_ADULT_PLHIV_VACCINATED_BY_AGE_GROUP_FETCH'
export const CT_COVID_ADULT_PLHIV_VACCINATED_BY_AGE_GROUP_FAILED = 'CT_COVID_ADULT_PLHIV_VACCINATED_BY_AGE_GROUP_FAILED'
export const CT_COVID_ADULT_PLHIV_VACCINATED_BY_GENDER_REQUEST = 'CT_COVID_ADULT_PLHIV_VACCINATED_BY_GENDER_REQUEST'
export const CT_COVID_ADULT_PLHIV_VACCINATED_BY_GENDER_FETCH = 'CT_COVID_ADULT_PLHIV_VACCINATED_BY_GENDER_FETCH'
export const CT_COVID_ADULT_PLHIV_VACCINATED_BY_GENDER_FAILED = 'CT_COVID_ADULT_PLHIV_VACCINATED_BY_GENDER_FAILED'
export const CT_COVID_ADULT_PLHIV_VACCINATED_BY_COUNTY_REQUEST = 'CT_COVID_ADULT_PLHIV_VACCINATED_BY_COUNTY_REQUEST'
export const CT_COVID_ADULT_PLHIV_VACCINATED_BY_COUNTY_FETCH = 'CT_COVID_ADULT_PLHIV_VACCINATED_BY_COUNTY_FETCH'
export const CT_COVID_ADULT_PLHIV_VACCINATED_BY_COUNTY_FAILED = 'CT_COVID_ADULT_PLHIV_VACCINATED_BY_COUNTY_FAILED'
export const CT_COVID_ADULT_PLHIV_VACCINATED_BY_PARTNER_REQUEST = 'CT_COVID_ADULT_PLHIV_VACCINATED_BY_PARTNER_REQUEST'
export const CT_COVID_ADULT_PLHIV_VACCINATED_BY_PARTNER_FETCH = 'CT_COVID_ADULT_PLHIV_VACCINATED_BY_PARTNER_FETCH'
export const CT_COVID_ADULT_PLHIV_VACCINATED_BY_PARTNER_FAILED = 'CT_COVID_ADULT_PLHIV_VACCINATED_BY_PARTNER_FAILED'
export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_GENDER_REQUEST = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_GENDER_REQUEST'
export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_GENDER_FETCH = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_GENDER_FETCH'
export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_GENDER_FAILED = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_GENDER_FAILED'
export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_COUNTY_REQUEST = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_COUNTY_REQUEST'
export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_COUNTY_FETCH = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_COUNTY_FETCH'
export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_COUNTY_FAILED = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_COUNTY_FAILED'
export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_PARTNER_REQUEST = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_PARTNER_REQUEST'
export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_PARTNER_FETCH = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_PARTNER_FETCH'
export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_PARTNER_FAILED = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_PARTNER_FAILED'

export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_AGE_GROUP_REQUEST = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_AGE_GROUP_REQUEST'
export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_AGE_GROUP_FETCH = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_AGE_GROUP_FETCH'
export const CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_AGE_GROUP_FAILED = 'CT_COVID_ADULT_PLHIV_CURRENT_ON_TREATMENT_BY_AGE_GROUP_FAILED'

export const CT_COVID_PLHIV_CURRENT_ON_ART_REQUEST = 'CT_COVID_PLHIV_CURRENT_ON_ART_REQUEST'
export const CT_COVID_PLHIV_CURRENT_ON_ART_FETCH = 'CT_COVID_PLHIV_CURRENT_ON_ART_FETCH'
export const CT_COVID_PLHIV_CURRENT_ON_ART_FAILED = 'CT_COVID_PLHIV_CURRENT_ON_ART_FAILED'

export const CT_COVID_SEVERITY_BY_GENDER_REQUEST = 'CT_COVID_SEVERITY_BY_GENDER_REQUEST'
export const CT_COVID_SEVERITY_BY_GENDER_FETCH = 'CT_COVID_SEVERITY_BY_GENDER_FETCH'
export const CT_COVID_SEVERITY_BY_GENDER_FAILED = 'CT_COVID_SEVERITY_BY_GENDER_FAILED'

export const CT_COVID_ADMISSION_OVERALL_REQUEST = 'CT_COVID_ADMISSION_OVERALL_REQUEST'
export const CT_COVID_ADMISSION_OVERALL_FETCH = 'CT_COVID_ADMISSION_OVERALL_FETCH'
export const CT_COVID_ADMISSION_OVERALL_FAILED = 'CT_COVID_ADMISSION_OVERALL_FAILED'

export const CT_COVID_ADMISSION_OVERALL_MALE_REQUEST = 'CT_COVID_ADMISSION_OVERALL_MALE_REQUEST'
export const CT_COVID_ADMISSION_OVERALL_MALE_FETCH = 'CT_COVID_ADMISSION_OVERALL_MALE_FETCH'
export const CT_COVID_ADMISSION_OVERALL_MALE_FAILED = 'CT_COVID_ADMISSION_OVERALL_MALE_FAILED'

export const CT_COVID_ADMISSION_OVERALL_FEMALE_REQUEST = 'CT_COVID_ADMISSION_OVERALL_FEMALE_REQUEST'
export const CT_COVID_ADMISSION_OVERALL_FEMALE_FETCH = 'CT_COVID_ADMISSION_OVERALL_FEMALE_FETCH'
export const CT_COVID_ADMISSION_OVERALL_FEMALE_FAILED = 'CT_COVID_ADMISSION_OVERALL_FEMALE_FAILED'

export const CT_EVER_HAD_COVID_INFECTION_REQUEST = 'CT_EVER_HAD_COVID_INFECTION_REQUEST'
export const CT_EVER_HAD_COVID_INFECTION_FETCH = 'CT_EVER_HAD_COVID_INFECTION_FETCH'
export const CT_EVER_HAD_COVID_INFECTION_FAILED = 'CT_EVER_HAD_COVID_INFECTION_FAILED'

export const CT_COVID_SYMPTOMATIC_INFECTIONS_REQUEST = 'CT_COVID_SYMPTOMATIC_INFECTIONS_REQUEST'
export const CT_COVID_SYMPTOMATIC_INFECTIONS_FETCH = 'CT_COVID_SYMPTOMATIC_INFECTIONS_FETCH'
export const CT_COVID_SYMPTOMATIC_INFECTIONS_FAILED = 'CT_COVID_SYMPTOMATIC_INFECTIONS_FAILED'

export const CT_COVID_OVERALL_MISSED_APPOINTMENTS_REQUEST = 'CT_COVID_OVERALL_MISSED_APPOINTMENTS_REQUEST'
export const CT_COVID_OVERALL_MISSED_APPOINTMENTS_FETCH = 'CT_COVID_OVERALL_MISSED_APPOINTMENTS_FETCH'
export const CT_COVID_OVERALL_MISSED_APPOINTMENTS_FAILED = 'CT_COVID_OVERALL_MISSED_APPOINTMENTS_FAILED'

export const CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_AGE_REQUEST = 'CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_AGE_REQUEST'
export const CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_AGE_FETCH = 'CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_AGE_FETCH'
export const CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_AGE_FAILED = 'CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_AGE_FAILED'

export const CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_COUNTY_REQUEST = 'CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_COUNTY_REQUEST'
export const CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_COUNTY_FETCH = 'CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_COUNTY_FETCH'
export const CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_COUNTY_FAILED = 'CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_COUNTY_FAILED'

export const CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_PARTNER_REQUEST = 'CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_PARTNER_REQUEST'
export const CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_PARTNER_FETCH = 'CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_PARTNER_FETCH'
export const CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_PARTNER_FAILED = 'CT_COVID_PERCENTAGE_MISSED_APPOINTMENTS_BY_PARTNER_FAILED'

export const CT_COVID_CUMULATIVE_WHO_RECEIVED_AT_LEAST_ONE_DOSE_REQUEST = 'CT_COVID_CUMULATIVE_WHO_RECEIVED_AT_LEAST_ONE_DOSE_REQUEST'
export const CT_COVID_CUMULATIVE_WHO_RECEIVED_AT_LEAST_ONE_DOSE_FETCH = 'CT_COVID_CUMULATIVE_WHO_RECEIVED_AT_LEAST_ONE_DOSE_FETCH'
export const CT_COVID_CUMULATIVE_WHO_RECEIVED_AT_LEAST_ONE_DOSE_FAILED = 'CT_COVID_CUMULATIVE_WHO_RECEIVED_AT_LEAST_ONE_DOSE_FAILED'

export const CT_COVID_CUMULATIVE_WITH_MISSING_DATE_GIVEN_FIRST_DOSE_REQUEST = 'CT_COVID_CUMULATIVE_WITH_MISSING_DATE_GIVEN_FIRST_DOSE_REQUEST'
export const CT_COVID_CUMULATIVE_WITH_MISSING_DATE_GIVEN_FIRST_DOSE_FETCH = 'CT_COVID_CUMULATIVE_WITH_MISSING_DATE_GIVEN_FIRST_DOSE_FETCH'
export const CT_COVID_CUMULATIVE_WITH_MISSING_DATE_GIVEN_FIRST_DOSE_FAILED = 'CT_COVID_CUMULATIVE_WITH_MISSING_DATE_GIVEN_FIRST_DOSE_FAILED'

export const CT_COVID_TRENDS_PLHIV_IN_THE_LAST_12_MONTHS_REQUEST = 'CT_COVID_TRENDS_PLHIV_IN_THE_LAST_12_MONTHS_REQUEST'
export const CT_COVID_TRENDS_PLHIV_IN_THE_LAST_12_MONTHS_FETCH = 'CT_COVID_TRENDS_PLHIV_IN_THE_LAST_12_MONTHS_FETCH'
export const CT_COVID_TRENDS_PLHIV_IN_THE_LAST_12_MONTHS_FAILED = 'CT_COVID_TRENDS_PLHIV_IN_THE_LAST_12_MONTHS_FAILED'

export const CT_OVC_OVERALL_CALHIV_REQUEST = 'CT_OVC_OVERALL_CALHIV_REQUEST'
export const CT_OVC_OVERALL_CALHIV_FETCH = 'CT_OVC_OVERALL_CALHIV_FETCH'
export const CT_OVC_OVERALL_CALHIV_FAILED = 'CT_OVC_OVERALL_CALHIV_FAILED'

export const CT_OVC_CALHIV_BY_GENDER_REQUEST = 'CT_OVC_CALHIV_BY_GENDER_REQUEST'
export const CT_OVC_CALHIV_BY_GENDER_FETCH = 'CT_OVC_CALHIV_BY_GENDER_FETCH'
export const CT_OVC_CALHIV_BY_GENDER_FAILED = 'CT_OVC_CALHIV_BY_GENDER_FAILED'

export const CT_OVC_DISTRIBUTION_OF_CALHIV_BY_AGE_SEX_REQUEST = 'CT_OVC_DISTRIBUTION_OF_CALHIV_BY_AGE_SEX_REQUEST'
export const CT_OVC_DISTRIBUTION_OF_CALHIV_BY_AGE_SEX_FETCH = 'CT_OVC_DISTRIBUTION_OF_CALHIV_BY_AGE_SEX_FETCH'
export const CT_OVC_DISTRIBUTION_OF_CALHIV_BY_AGE_SEX_FAILED = 'CT_OVC_DISTRIBUTION_OF_CALHIV_BY_AGE_SEX_FAILED'

export const CT_OVC_DISTRIBUTION_OF_PATIENTS_BY_AGE_SEX_REQUEST = 'CT_OVC_DISTRIBUTION_OF_PATIENTS_BY_AGE_SEX_REQUEST'
export const CT_OVC_DISTRIBUTION_OF_PATIENTS_BY_AGE_SEX_FETCH = 'CT_OVC_DISTRIBUTION_OF_PATIENTS_BY_AGE_SEX_FETCH'
export const CT_OVC_DISTRIBUTION_OF_PATIENTS_BY_AGE_SEX_FAILED = 'CT_OVC_DISTRIBUTION_OF_PATIENTS_BY_AGE_SEX_FAILED'

export const CT_CALHIV_CURRENT_ON_ART_REQUEST = 'CT_CALHIV_CURRENT_ON_ART_REQUEST'
export const CT_CALHIV_CURRENT_ON_ART_FETCH = 'CT_CALHIV_CURRENT_ON_ART_FETCH'
export const CT_CALHIV_CURRENT_ON_ART_FAILED = 'CT_CALHIV_CURRENT_ON_ART_FAILED'

export const CT_CALHIV_CURRENT_ON_ART_NOT_IN_OVC_REQUEST = 'CT_CALHIV_CURRENT_ON_ART_NOT_IN_OVC_REQUEST'
export const CT_CALHIV_CURRENT_ON_ART_NOT_IN_OVC_FETCH = 'CT_CALHIV_CURRENT_ON_ART_NOT_IN_OVC_FETCH'
export const CT_CALHIV_CURRENT_ON_ART_NOT_IN_OVC_FAILED = 'CT_CALHIV_CURRENT_ON_ART_NOT_IN_OVC_FAILED'

export const CT_CALHIV_TOTAL_ON_DTG_REQUEST = 'CT_CALHIV_TOTAL_ON_DTG_REQUEST'
export const CT_CALHIV_TOTAL_ON_DTG_FETCH = 'CT_CALHIV_TOTAL_ON_DTG_FETCH'
export const CT_CALHIV_TOTAL_ON_DTG_FAILED = 'CT_CALHIV_TOTAL_ON_DTG_FAILED'

export const CT_OVC_CURRENT_ON_ART_REQUEST = 'CT_OVC_CURRENT_ON_ART_REQUEST'
export const CT_OVC_CURRENT_ON_ART_FETCH = 'CT_OVC_CURRENT_ON_ART_FETCH'
export const CT_OVC_CURRENT_ON_ART_FAILED = 'CT_OVC_CURRENT_ON_ART_FAILED'

export const CT_OVC_TOTAL_ON_TLD_REQUEST = 'CT_OVC_TOTAL_ON_TLD_REQUEST'
export const CT_OVC_TOTAL_ON_TLD_FETCH = 'CT_OVC_TOTAL_ON_TLD_FETCH'
export const CT_OVC_TOTAL_ON_TLD_FAILED = 'CT_OVC_TOTAL_ON_TLD_FAILED'

export const CT_CALHIV_TOTAL_ON_MMD_REQUEST = 'CT_CALHIV_TOTAL_ON_MMD_REQUEST'
export const CT_CALHIV_TOTAL_ON_MMD_FETCH = 'CT_CALHIV_TOTAL_ON_MMD_FETCH'
export const CT_CALHIV_TOTAL_ON_MMD_FAILED = 'CT_CALHIV_TOTAL_ON_MMD_FAILED'

export const CT_OVC_TOTAL_ON_MMD_REQUEST = 'CT_OVC_TOTAL_ON_MMD_REQUEST'
export const CT_OVC_TOTAL_ON_MMD_FETCH = 'CT_OVC_TOTAL_ON_MMD_FETCH'
export const CT_OVC_TOTAL_ON_MMD_FAILED = 'CT_OVC_TOTAL_ON_MMD_FAILED'

export const CT_CALHIV_IIT_REQUEST = 'CT_CALHIV_IIT_REQUEST'
export const CT_CALHIV_IIT_FETCH = 'CT_CALHIV_IIT_FETCH'
export const CT_CALHIV_IIT_FAILED = 'CT_CALHIV_IIT_FAILED'

export const CT_CALHIV_DEAD_REQUEST = 'CT_CALHIV_DEAD_REQUEST'
export const CT_CALHIV_DEAD_FETCH = 'CT_CALHIV_DEAD_FETCH'
export const CT_CALHIV_DEAD_FAILED = 'CT_CALHIV_DEAD_FAILED'

export const CT_OVC_IIT_REQUEST = 'CT_OVC_IIT_REQUEST'
export const CT_OVC_IIT_FETCH = 'CT_OVC_IIT_FETCH'
export const CT_OVC_IIT_FAILED = 'CT_OVC_IIT_FAILED'

export const CT_OVC_DEAD_REQUEST = 'CT_OVC_DEAD_REQUEST'
export const CT_OVC_DEAD_FETCH = 'CT_OVC_DEAD_FETCH'
export const CT_OVC_DEAD_FAILED = 'CT_OVC_DEAD_FAILED'

export const CT_CALHIV_ELIGIBLE_VL_REQUEST = 'CT_CALHIV_ELIGIBLE_VL_REQUEST'
export const CT_CALHIV_ELIGIBLE_VL_FETCH = 'CT_CALHIV_ELIGIBLE_VL_FETCH'
export const CT_CALHIV_ELIGIBLE_VL_FAILED = 'CT_CALHIV_ELIGIBLE_VL_FAILED'

export const CT_CALHIV_VL_DONE_REQUEST = 'CT_CALHIV_VL_DONE_REQUEST'
export const CT_CALHIV_VL_DONE_FETCH = 'CT_CALHIV_VL_DONE_FETCH'
export const CT_CALHIV_VL_DONE_FAILED = 'CT_CALHIV_VL_DONE_FAILED'

export const CT_CALHIV_VL_SUPPRESSED_REQUEST = 'CT_CALHIV_VL_SUPPRESSED_REQUEST'
export const CT_CALHIV_VL_SUPPRESSED_FETCH = 'CT_CALHIV_VL_SUPPRESSED_FETCH'
export const CT_CALHIV_VL_SUPPRESSED_FAILED = 'CT_CALHIV_VL_SUPPRESSED_FAILED'


export const CT_OVC_ELIGIBLE_VL_REQUEST = 'CT_OVC_ELIGIBLE_VL_REQUEST'
export const CT_OVC_ELIGIBLE_VL_FETCH = 'CT_OVC_ELIGIBLE_VL_FETCH'
export const CT_OVC_ELIGIBLE_VL_FAILED = 'CT_OVC_ELIGIBLE_VL_FAILED'

export const CT_OVC_VL_DONE_REQUEST = 'CT_OVC_VL_DONE_REQUEST'
export const CT_OVC_VL_DONE_FETCH = 'CT_OVC_VL_DONE_FETCH'
export const CT_OVC_VL_DONE_FAILED = 'CT_OVC_VL_DONE_FAILED'

export const CT_OVC_VL_SUPPRESSED_REQUEST = 'CT_OVC_VL_SUPPRESSED_REQUEST'
export const CT_OVC_VL_SUPPRESSED_FETCH = 'CT_OVC_VL_SUPPRESSED_FETCH'
export const CT_OVC_VL_SUPPRESSED_FAILED = 'CT_OVC_VL_SUPPRESSED_FAILED'

export const CT_COVID_NUMBER_SCREENED_REQUEST = 'CT_COVID_NUMBER_SCREENED_REQUEST'
export const CT_COVID_NUMBER_SCREENED_FETCH = 'CT_COVID_NUMBER_SCREENED_FETCH'
export const CT_COVID_NUMBER_SCREENED_FAILED = 'CT_COVID_NUMBER_SCREENED_FAILED'

export const CT_COVID_ADMISSION_SYMPTOMATIC_PATIENTS_BY_AGE_REQUEST = 'CT_COVID_ADMISSION_SYMPTOMATIC_PATIENTS_BY_AGE_REQUEST'
export const CT_COVID_ADMISSION_SYMPTOMATIC_PATIENTS_BY_AGE_FETCH = 'CT_COVID_ADMISSION_SYMPTOMATIC_PATIENTS_BY_AGE_FETCH'
export const CT_COVID_ADMISSION_SYMPTOMATIC_PATIENTS_BY_AGE_FAILED = 'CT_COVID_ADMISSION_SYMPTOMATIC_PATIENTS_BY_AGE_FAILED'

export const CT_COVID_MANAGEMENT_ADMITTED_REQUEST = 'CT_COVID_MANAGEMENT_ADMITTED_REQUEST'
export const CT_COVID_MANAGEMENT_ADMITTED_FETCH = 'CT_COVID_MANAGEMENT_ADMITTED_FETCH'
export const CT_COVID_MANAGEMENT_ADMITTED_FAILED = 'CT_COVID_MANAGEMENT_ADMITTED_FAILED'

export const KHIS_NEWLY_STARTED_ART_REQUEST = 'KHIS_NEWLY_STARTED_ART_REQUEST'
export const KHIS_NEWLY_STARTED_ART_FETCH = 'KHIS_NEWLY_STARTED_ART_FETCH'
export const KHIS_NEWLY_STARTED_ART_FAILED = 'KHIS_NEWLY_STARTED_ART_FAILED'

export const KHIS_NEWLY_STARTED_ART_TRENDS_REQUEST = 'KHIS_NEWLY_STARTED_ART_TRENDS_REQUEST'
export const KHIS_NEWLY_STARTED_ART_TRENDS_FETCH = 'KHIS_NEWLY_STARTED_ART_TRENDS_FETCH'
export const KHIS_NEWLY_STARTED_ART_TRENDS_FAILED = 'KHIS_NEWLY_STARTED_ART_TRENDS_FAILED'

export const KHIS_HTS_POSITIVES_TRENDS_REQUEST = 'KHIS_HTS_POSITIVES_TRENDS_REQUEST'
export const KHIS_HTS_POSITIVES_TRENDS_FETCH = 'KHIS_HTS_POSITIVES_TRENDS_FETCH'
export const KHIS_HTS_POSITIVES_TRENDS_FAILED = 'KHIS_HTS_POSITIVES_TRENDS_FAILED'

export const KHIS_CURR_ON_ART_REQUEST = 'KHIS_CURR_ON_ART_REQUEST'
export const KHIS_CURR_ON_ART_FETCH = 'KHIS_CURR_ON_ART_FETCH'
export const KHIS_CURR_ON_ART_FAILED = 'KHIS_CURR_ON_ART_FAILED'

export const KHIS_CURR_ON_ART_BY_COUNTY_REQUEST = 'KHIS_CURR_ON_ART_BY_COUNTY_REQUEST'
export const KHIS_CURR_ON_ART_BY_COUNTY_FETCH = 'KHIS_CURR_ON_ART_BY_COUNTY_FETCH'
export const KHIS_CURR_ON_ART_BY_COUNTY_FAILED = 'KHIS_CURR_ON_ART_BY_COUNTY_FAILED'

export const DWH_CURR_ON_ART_BY_COUNTY_REQUEST = 'DWH_CURR_ON_ART_BY_COUNTY_REQUEST'
export const DWH_CURR_ON_ART_BY_COUNTY_FETCH = 'DWH_CURR_ON_ART_BY_COUNTY_FETCH'
export const DWH_CURR_ON_ART_BY_COUNTY_FAILED = 'DWH_CURR_ON_ART_BY_COUNTY_FAILED'

export const KHIS_CURR_ON_ART_BY_PARTNER_REQUEST = 'KHIS_CURR_ON_ART_BY_PARTNER_REQUEST'
export const KHIS_CURR_ON_ART_BY_PARTNER_FETCH = 'KHIS_CURR_ON_ART_BY_PARTNER_FETCH'
export const KHIS_CURR_ON_ART_BY_PARTNER_FAILED = 'KHIS_CURR_ON_ART_BY_PARTNER_FAILED'

export const KHIS_CURR_ON_ART_BY_PARTNER_FACILITY_REQUEST = 'KHIS_CURR_ON_ART_BY_PARTNER_FACILITY_REQUEST'
export const KHIS_CURR_ON_ART_BY_PARTNER_FACILITY_FETCH = 'KHIS_CURR_ON_ART_BY_PARTNER_FACILITY_FETCH'
export const KHIS_CURR_ON_ART_BY_PARTNER_FACILITY_FAILED = 'KHIS_CURR_ON_ART_BY_PARTNER_FACILITY_FAILED'

export const KHIS_NEW_ON_ART_BY_PARTNER_FACILITY_REQUEST = 'KHIS_NEW_ON_ART_BY_PARTNER_FACILITY_REQUEST'
export const KHIS_NEW_ON_ART_BY_PARTNER_FACILITY_FETCH = 'KHIS_NEW_ON_ART_BY_PARTNER_FACILITY_FETCH'
export const KHIS_NEW_ON_ART_BY_PARTNER_FACILITY_FAILED = 'KHIS_NEW_ON_ART_BY_PARTNER_FACILITY_FAILED'

export const DWH_NEW_ON_ART_BY_PARTNER_FACILITY_REQUEST = 'DWH_NEW_ON_ART_BY_PARTNER_FACILITY_REQUEST'
export const DWH_NEW_ON_ART_BY_PARTNER_FACILITY_FETCH = 'DWH_NEW_ON_ART_BY_PARTNER_FACILITY_FETCH'
export const DWH_NEW_ON_ART_BY_PARTNER_FACILITY_FAILED = 'DWH_NEW_ON_ART_BY_PARTNER_FACILITY_FAILED'

export const DWH_CURR_ON_ART_BY_PARTNER_FACILITY_REQUEST = 'DWH_CURR_ON_ART_BY_PARTNER_FACILITY_REQUEST'
export const DWH_CURR_ON_ART_BY_PARTNER_FACILITY_FETCH = 'DWH_CURR_ON_ART_BY_PARTNER_FACILITY_FETCH'
export const DWH_CURR_ON_ART_BY_PARTNER_FACILITY_FAILED = 'DWH_CURR_ON_ART_BY_PARTNER_FACILITY_FAILED'

export const CT_TX_CURR_VERIFIED_REQUEST = 'CT_TX_CURR_VERIFIED_REQUEST';
export const CT_TX_CURR_VERIFIED_FETCH = 'CT_TX_CURR_VERIFIED_FETCH';
export const CT_TX_CURR_VERIFIED_FAILED = 'CT_TX_CURR_VERIFIED_FAILED';

export const CT_TX_CURR_AGE_SEX_VERIFIED_REQUEST = 'CT_TX_CURR_AGE_SEX_VERIFIED_REQUEST';
export const CT_TX_CURR_AGE_SEX_VERIFIED_FETCH = 'CT_TX_CURR_AGE_SEX_VERIFIED_FETCH';
export const CT_TX_CURR_AGE_SEX_VERIFIED_FAILED = 'CT_TX_CURR_AGE_SEX_VERIFIED_FAILED';

export const CT_TX_CURR_PARTNER_VERIFIED_REQUEST = 'CT_TX_CURR_PARTNER_VERIFIED_REQUEST';
export const CT_TX_CURR_PARTNER_VERIFIED_FETCH = 'CT_TX_CURR_PARTNER_VERIFIED_FETCH';
export const CT_TX_CURR_PARTNER_VERIFIED_FAILED = 'CT_TX_CURR_PARTNER_VERIFIED_FAILED';

export const CT_TX_CURR_COUNTY_VERIFIED_REQUEST = 'CT_TX_CURR_COUNTY_VERIFIED_REQUEST';
export const CT_TX_CURR_COUNTY_VERIFIED_FETCH = 'CT_TX_CURR_COUNTY_VERIFIED_FETCH';
export const CT_TX_CURR_COUNTY_VERIFIED_FAILED = 'CT_TX_CURR_COUNTY_VERIFIED_FAILED';

export const CT_TX_CURR_FACILITY_VERIFIED_REQUEST = 'CT_TX_CURR_FACILITY_VERIFIED_REQUEST';
export const CT_TX_CURR_FACILITY_VERIFIED_FETCH = 'CT_TX_CURR_FACILITY_VERIFIED_FETCH';
export const CT_TX_CURR_FACILITY_VERIFIED_FAILED = 'CT_TX_CURR_FACILITY_VERIFIED_FAILED';

export const CT_TX_CURR_FACILITY_REQUEST = 'CT_TX_CURR_FACILITY_REQUEST';
export const CT_TX_CURR_FACILITY_FETCH = 'CT_TX_CURR_FACILITY_FETCH';
export const CT_TX_CURR_FACILITY_FAILED = 'CT_TX_CURR_FACILITY_FAILED';

export const KHIS_HTS_POS_BY_GENDER_REQUEST = 'KHIS_HTS_POS_BY_GENDER_REQUEST';
export const KHIS_HTS_POS_BY_GENDER_FETCH = 'KHIS_HTS_POS_BY_GENDER_FETCH';
export const KHIS_HTS_POS_BY_GENDER_FAILED = 'KHIS_HTS_POS_BY_GENDER_FAILED';

export const KHIS_HTS_POS_BY_COUNTY_REQUEST = 'KHIS_HTS_POS_BY_COUNTY_REQUEST';
export const KHIS_HTS_POS_BY_COUNTY_FETCH = 'KHIS_HTS_POS_BY_COUNTY_FETCH';
export const KHIS_HTS_POS_BY_COUNTY_FAILED = 'KHIS_HTS_POS_BY_COUNTY_FAILED';

export const KHIS_HTS_POS_BY_PARTNER_REQUEST = 'KHIS_HTS_POS_BY_PARTNER_REQUEST';
export const KHIS_HTS_POS_BY_PARTNER_FETCH = 'KHIS_HTS_POS_BY_PARTNER_FETCH';
export const KHIS_HTS_POS_BY_PARTNER_FAILED = 'KHIS_HTS_POS_BY_PARTNER_FAILED';

export const KHIS_HTS_POS_BY_FACILITY_REQUEST = 'KHIS_HTS_POS_BY_FACILITY_REQUEST';
export const KHIS_HTS_POS_BY_FACILITY_FETCH = 'KHIS_HTS_POS_BY_FACILITY_FETCH';
export const KHIS_HTS_POS_BY_FACILITY_FAILED = 'KHIS_HTS_POS_BY_FACILITY_FAILED';

export const KHIS_HTS_TEST_BY_GENDER_REQUEST = 'KHIS_HTS_TEST_BY_GENDER_REQUEST';
export const KHIS_HTS_TEST_BY_GENDER_FETCH = 'KHIS_HTS_TEST_BY_GENDER_FETCH';
export const KHIS_HTS_TEST_BY_GENDER_FAILED = 'KHIS_HTS_TEST_BY_GENDER_FAILED';

export const KHIS_HTS_TEST_BY_COUNTY_REQUEST = 'KHIS_HTS_TEST_BY_COUNTY_REQUEST';
export const KHIS_HTS_TEST_BY_COUNTY_FETCH = 'KHIS_HTS_TEST_BY_COUNTY_FETCH';
export const KHIS_HTS_TEST_BY_COUNTY_FAILED = 'KHIS_HTS_TEST_BY_COUNTY_FAILED';

export const KHIS_HTS_TEST_BY_PARTNER_REQUEST = 'KHIS_HTS_TEST_BY_PARTNER_REQUEST';
export const KHIS_HTS_TEST_BY_PARTNER_FETCH = 'KHIS_HTS_TEST_BY_PARTNER_FETCH';
export const KHIS_HTS_TEST_BY_PARTNER_FAILED = 'KHIS_HTS_TEST_BY_PARTNER_FAILED';

export const KHIS_HTS_TEST_BY_FACILITY_REQUEST = 'KHIS_HTS_TEST_BY_FACILITY_REQUEST';
export const KHIS_HTS_TEST_BY_FACILITY_FETCH = 'KHIS_HTS_TEST_BY_FACILITY_FETCH';
export const KHIS_HTS_TEST_BY_FACILITY_FAILED = 'KHIS_HTS_TEST_BY_FACILITY_FAILED';

export const DWH_HTS_POS_BY_FACILITY_REQUEST = 'DWH_HTS_POS_BY_FACILITY_REQUEST';
export const DWH_HTS_POS_BY_FACILITY_FETCH = 'DWH_HTS_POS_BY_FACILITY_FETCH';
export const DWH_HTS_POS_BY_FACILITY_FAILED = 'DWH_HTS_POS_BY_FACILITY_FAILED';

export const DWH_HTS_POS_BY_AGE_REQUEST = 'DWH_HTS_POS_BY_AGE_REQUEST';
export const DWH_HTS_POS_BY_AGE_FETCH = 'DWH_HTS_POS_BY_AGE_FETCH';
export const DWH_HTS_POS_BY_AGE_FAILED = 'DWH_HTS_POS_BY_AGE_FAILED';

export const DWH_HTS_POS_BY_GENDER_REQUEST = 'DWH_HTS_POS_BY_GENDER_REQUEST';
export const DWH_HTS_POS_BY_GENDER_FETCH = 'DWH_HTS_POS_BY_GENDER_FETCH';
export const DWH_HTS_POS_BY_GENDER_FAILED = 'DWH_HTS_POS_BY_GENDER_FAILED';

export const DWH_HTS_POS_BY_PARTNER_REQUEST = 'DWH_HTS_POS_BY_PARTNER_REQUEST';
export const DWH_HTS_POS_BY_PARTNER_FETCH = 'DWH_HTS_POS_BY_PARTNER_FETCH';
export const DWH_HTS_POS_BY_PARTNER_FAILED = 'DWH_HTS_POS_BY_PARTNER_FAILED';

export const DWH_HTS_POS_BY_COUNTY_REQUEST = 'DWH_HTS_POS_BY_COUNTY_REQUEST';
export const DWH_HTS_POS_BY_COUNTY_FETCH = 'DWH_HTS_POS_BY_COUNTY_FETCH';
export const DWH_HTS_POS_BY_COUNTY_FAILED = 'DWH_HTS_POS_BY_COUNTY_FAILED';

export const DWH_HTS_TEST_TRENDS_REQUEST = 'DWH_HTS_TEST_TRENDS_REQUEST';
export const DWH_HTS_TEST_TRENDS_FETCH = 'DWH_HTS_TEST_TRENDS_FETCH';
export const DWH_HTS_TEST_TRENDS_FAILED = 'DWH_HTS_TEST_TRENDS_FAILED';

export const ART_VERIFICATION_BY_PARTNER_REQUEST = 'ART_VERIFICATION_BY_PARTNER_REQUEST';
export const ART_VERIFICATION_BY_PARTNER_FETCH = 'ART_VERIFICATION_BY_PARTNER_FETCH';
export const ART_VERIFICATION_BY_PARTNER_FAILED = 'ART_VERIFICATION_BY_PARTNER_FAILED';

export const ART_VERIFICATION_BY_COUNTY_REQUEST = 'ART_VERIFICATION_BY_COUNTY_REQUEST';
export const ART_VERIFICATION_BY_COUNTY_FETCH = 'ART_VERIFICATION_BY_COUNTY_FETCH';
export const ART_VERIFICATION_BY_COUNTY_FAILED = 'ART_VERIFICATION_BY_COUNTY_FAILED';

export const ART_VERIFICATION_REASONS_REQUEST = 'ART_VERIFICATION_REASONS_REQUEST';
export const ART_VERIFICATION_REASONS_FETCH = 'ART_VERIFICATION_REASONS_FETCH';
export const ART_VERIFICATION_REASONS_FAILED = 'ART_VERIFICATION_REASONS_FAILED';

export const SERVICE_DESK_OVERVIEW_REQUEST = 'SERVICE_DESK_OVERVIEW_REQUEST';
export const SERVICE_DESK_OVERVIEW_FETCH = 'SERVICE_DESK_OVERVIEW_FETCH';
export const SERVICE_DESK_OVERVIEW_FAILED = 'SERVICE_DESK_OVERVIEW_FAILED';

export const SERVICE_DESK_STATUS_BY_MONTH_REQUEST = 'SERVICE_DESK_STATUS_BY_MONTH_REQUEST';
export const SERVICE_DESK_STATUS_BY_MONTH_FETCH = 'SERVICE_DESK_STATUS_BY_MONTH_FETCH';
export const SERVICE_DESK_STATUS_BY_MONTH_FAILED = 'SERVICE_DESK_STATUS_BY_MONTH_FAILED';

export const SERVICE_DESK_STATUS_BY_PRODUCT_REQUEST = 'SERVICE_DESK_STATUS_BY_PRODUCT_REQUEST';
export const SERVICE_DESK_STATUS_BY_PRODUCT_FETCH = 'SERVICE_DESK_STATUS_BY_PRODUCT_FETCH';
export const SERVICE_DESK_STATUS_BY_PRODUCT_FAILED = 'SERVICE_DESK_STATUS_BY_PRODUCT_FAILED';

export const SERVICE_DESK_OPEN_BY_COUNTY_REQUEST = 'SERVICE_DESK_OPEN_BY_COUNTY_REQUEST';
export const SERVICE_DESK_OPEN_BY_COUNTY_FETCH = 'SERVICE_DESK_OPEN_BY_COUNTY_FETCH';
export const SERVICE_DESK_OPEN_BY_COUNTY_FAILED = 'SERVICE_DESK_OPEN_BY_COUNTY_FAILED';

export const SERVICE_DESK_OPEN_BY_MONTH_REQUEST = 'SERVICE_DESK_OPEN_BY_MONTH_REQUEST';
export const SERVICE_DESK_OPEN_BY_MONTH_FETCH = 'SERVICE_DESK_OPEN_BY_MONTH_FETCH';
export const SERVICE_DESK_OPEN_BY_MONTH_FAILED = 'SERVICE_DESK_OPEN_BY_MONTH_FAILED';

export const SERVICE_DESK_OPEN_BY_PARTNER_REQUEST = 'SERVICE_DESK_OPEN_BY_PARTNER_REQUEST';
export const SERVICE_DESK_OPEN_BY_PARTNER_FETCH = 'SERVICE_DESK_OPEN_BY_PARTNER_FETCH';
export const SERVICE_DESK_OPEN_BY_PARTNER_FAILED = 'SERVICE_DESK_OPEN_BY_PARTNER_FAILED';

export const SERVICE_DESK_OPEN_BY_PRODUCT_REQUEST = 'SERVICE_DESK_OPEN_BY_PRODUCT_REQUEST';
export const SERVICE_DESK_OPEN_BY_PRODUCT_FETCH = 'SERVICE_DESK_OPEN_BY_PRODUCT_FETCH';
export const SERVICE_DESK_OPEN_BY_PRODUCT_FAILED = 'SERVICE_DESK_OPEN_BY_PRODUCT_FAILED';

export const SERVICE_DESK_PARTNER_LEVEL_REQUEST = 'SERVICE_DESK_PARTNER_LEVEL_REQUEST';
export const SERVICE_DESK_PARTNER_LEVEL_FETCH = 'SERVICE_DESK_PARTNER_LEVEL_FETCH';
export const SERVICE_DESK_PARTNER_LEVEL_FAILED = 'SERVICE_DESK_PARTNER_LEVEL_FAILED';

export const PMTCT_RRI_MISSED_FIRST_ANC_OVERVIEW_REQUEST = 'PMTCT_RRI_MISSED_FIRST_ANC_OVERVIEW_REQUEST';
export const PMTCT_RRI_MISSED_FIRST_ANC_OVERVIEW_FETCH = 'PMTCT_RRI_MISSED_FIRST_ANC_OVERVIEW_FETCH';
export const PMTCT_RRI_MISSED_FIRST_ANC_OVERVIEW_FAILED = 'PMTCT_RRI_MISSED_FIRST_ANC_OVERVIEW_FAILED';

export const PMTCT_RRI_MISSED_FIRST_ANC_COUNTY_REQUEST = 'PMTCT_RRI_MISSED_FIRST_ANC_COUNTY_REQUEST';
export const PMTCT_RRI_MISSED_FIRST_ANC_COUNTY_FETCH = 'PMTCT_RRI_MISSED_FIRST_ANC_COUNTY_FETCH';
export const PMTCT_RRI_MISSED_FIRST_ANC_COUNTY_FAILED = 'PMTCT_RRI_MISSED_FIRST_ANC_COUNTY_FAILED';

export const PMTCT_RRI_MISSED_FIRST_ANC_PARTNER_REQUEST = 'PMTCT_RRI_MISSED_FIRST_ANC_PARTNER_REQUEST';
export const PMTCT_RRI_MISSED_FIRST_ANC_PARTNER_FETCH = 'PMTCT_RRI_MISSED_FIRST_ANC_PARTNER_FETCH';
export const PMTCT_RRI_MISSED_FIRST_ANC_PARTNER_FAILED = 'PMTCT_RRI_MISSED_FIRST_ANC_PARTNER_FAILED';

export const PMTCT_RRI_MISSED_FIRST_ANC_GAPS_REQUEST = 'PMTCT_RRI_MISSED_FIRST_ANC_GAPS_REQUEST';
export const PMTCT_RRI_MISSED_FIRST_ANC_GAPS_FETCH = 'PMTCT_RRI_MISSED_FIRST_ANC_GAPS_FETCH';
export const PMTCT_RRI_MISSED_FIRST_ANC_GAPS_FAILED = 'PMTCT_RRI_MISSED_FIRST_ANC_GAPS_FAILED';

export const PMTCT_RRI_MISSED_MATERNAL_HAART_OVERVIEW_REQUEST = 'PMTCT_RRI_MISSED_MATERNAL_HAART_OVERVIEW_REQUEST';
export const PMTCT_RRI_MISSED_MATERNAL_HAART_OVERVIEW_FETCH = 'PMTCT_RRI_MISSED_MATERNAL_HAART_OVERVIEW_FETCH';
export const PMTCT_RRI_MISSED_MATERNAL_HAART_OVERVIEW_FAILED = 'PMTCT_RRI_MISSED_MATERNAL_HAART_OVERVIEW_FAILED';

export const PMTCT_RRI_MISSED_MATERNAL_HAART_COUNTY_REQUEST = 'PMTCT_RRI_MISSED_MATERNAL_HAART_COUNTY_REQUEST';
export const PMTCT_RRI_MISSED_MATERNAL_HAART_COUNTY_FETCH = 'PMTCT_RRI_MISSED_MATERNAL_HAART_COUNTY_FETCH';
export const PMTCT_RRI_MISSED_MATERNAL_HAART_COUNTY_FAILED = 'PMTCT_RRI_MISSED_MATERNAL_HAART_COUNTY_FAILED';

export const PMTCT_RRI_MISSED_MATERNAL_HAART_PARTNER_REQUEST = 'PMTCT_RRI_MISSED_MATERNAL_HAART_PARTNER_REQUEST';
export const PMTCT_RRI_MISSED_MATERNAL_HAART_PARTNER_FETCH = 'PMTCT_RRI_MISSED_MATERNAL_HAART_PARTNER_FETCH';
export const PMTCT_RRI_MISSED_MATERNAL_HAART_PARTNER_FAILED = 'PMTCT_RRI_MISSED_MATERNAL_HAART_PARTNER_FAILED';

export const PMTCT_RRI_MISSED_MATERNAL_HAART_FACILITY_REQUEST = 'PMTCT_RRI_MISSED_MATERNAL_HAART_FACILITY_REQUEST';
export const PMTCT_RRI_MISSED_MATERNAL_HAART_FACILITY_FETCH = 'PMTCT_RRI_MISSED_MATERNAL_HAART_FACILITY_FETCH';
export const PMTCT_RRI_MISSED_MATERNAL_HAART_FACILITY_FAILED = 'PMTCT_RRI_MISSED_MATERNAL_HAART_FACILITY_FAILED';

export const PMTCT_RRI_MISSED_EID_OVERVIEW_REQUEST = 'PMTCT_RRI_MISSED_EID_OVERVIEW_REQUEST';
export const PMTCT_RRI_MISSED_EID_OVERVIEW_FETCH = 'PMTCT_RRI_MISSED_EID_OVERVIEW_FETCH';
export const PMTCT_RRI_MISSED_EID_OVERVIEW_FAILED = 'PMTCT_RRI_MISSED_EID_OVERVIEW_FAILED';

export const PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_COUNTY_REQUEST = 'PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_COUNTY_REQUEST';
export const PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_COUNTY_FETCH = 'PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_COUNTY_FETCH';
export const PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_COUNTY_FAILED = 'PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_COUNTY_FAILED';

export const PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_PARTNER_REQUEST = 'PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_PARTNER_REQUEST';
export const PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_PARTNER_FETCH = 'PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_PARTNER_FETCH';
export const PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_PARTNER_FAILED = 'PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_PARTNER_FAILED';

export const PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_REQUEST = 'PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_REQUEST';
export const PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_FETCH = 'PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_FETCH';
export const PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_FAILED = 'PMTCT_RRI_MISSED_EID_AGE_FIRST_PCR_FAILED';

export const PMTCT_RRI_MISSED_EID_MISSING_PCR_REQUEST = 'PMTCT_RRI_MISSED_EID_MISSING_PCR_REQUEST';
export const PMTCT_RRI_MISSED_EID_MISSING_PCR_FETCH = 'PMTCT_RRI_MISSED_EID_MISSING_PCR_FETCH';
export const PMTCT_RRI_MISSED_EID_MISSING_PCR_FAILED = 'PMTCT_RRI_MISSED_EID_MISSING_PCR_FAILED';

export const PMTCT_RRI_MISSED_INFANT_PROPHYLAXIS_REQUEST = 'PMTCT_RRI_MISSED_INFANT_PROPHYLAXIS_REQUEST';
export const PMTCT_RRI_MISSED_INFANT_PROPHYLAXIS_FETCH = 'PMTCT_RRI_MISSED_INFANT_PROPHYLAXIS_FETCH';
export const PMTCT_RRI_MISSED_INFANT_PROPHYLAXIS_FAILED = 'PMTCT_RRI_MISSED_INFANT_PROPHYLAXIS_FAILED';

export const PMTCT_RRI_MISSED_VIRAL_LOAD_REQUEST = 'PMTCT_RRI_MISSED_VIRAL_LOAD_REQUEST';
export const PMTCT_RRI_MISSED_VIRAL_LOAD_FETCH = 'PMTCT_RRI_MISSED_VIRAL_LOAD_FETCH';
export const PMTCT_RRI_MISSED_VIRAL_LOAD_FAILED = 'PMTCT_RRI_MISSED_VIRAL_LOAD_FAILED';

export const PMTCT_RRI_MISSED_DTG_REQUEST = 'PMTCT_RRI_MISSED_DTG_REQUEST';
export const PMTCT_RRI_MISSED_DTG_FETCH = 'PMTCT_RRI_MISSED_DTG_FETCH';
export const PMTCT_RRI_MISSED_DTG_FAILED = 'PMTCT_RRI_MISSED_DTG_FAILED';
